<template>
    <div style="position: relative;">
      <div v-if="loading" style="height:  100%; width: 100%;" class="d-flex">
        <v-progress-circular        
        indeterminate
        size="64"
        class="ma-auto"
      ></v-progress-circular>
      </div>      
      <template v-else-if="$vuetify.breakpoint.mdAndUp && showOrderCreator">
        <OrderCreator             
          @closeOrderCreator="closeOrderCreator"
          @refreshMetadata="$emit('refreshMetadata')"
          :value="selectedOrder"
        ></OrderCreator>
      </template>
      <template v-else>
        <v-tabs
          fixed-tabs        
          v-model="selectedTab"        
          style="height: 72px;"
          class="sale-tabs"        
        >
          <v-tab style="font-size: 16px">
            รายการขาย
          </v-tab>
          <v-tab style="font-size: 16px">
            ข้อมูลลูกค้า
          </v-tab>      
        </v-tabs>

        <v-tabs-items style="height: calc(100% - 72px);" v-model="selectedTab">
          <v-tab-item
            :key="0"          
          >
            <OrderList           
            @callOrderCreator="callOrderCreator"
            @editOrder="editOrder"
            :orders="orders"
            ></OrderList>          
          </v-tab-item>
          <v-tab-item
            :key="1"          
          >
            <customer-info
              :conversation="$attrs.value"
              :customer="customer"
              @removeTag="$emit('removeTag', $event)"
            ></customer-info>
          </v-tab-item>
        </v-tabs-items>

        <v-dialog
          v-if="!$vuetify.breakpoint.mdAndUp"
          v-model="showOrderCreator"
          transition="dialog-bottom-transition"
          persistent
          fullscreen
        >
          <OrderCreator             
            @closeOrderCreator="closeOrderCreator"
            @refreshMetadata="$emit('refreshMetadata')"
            :value="selectedOrder"
          ></OrderCreator>          
        </v-dialog>
      </template>

      <v-fab-transition>
        <v-btn            
          color="primary"
          dark
          fixed
          bottom
          right
          fab
          style="bottom: 100px;"
          @click="$emit('closeChatSale')"
          v-if="!$vuetify.breakpoint.mdAndUp"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>    
    <!--
    <transition 
    v-else
    name="slide-in-left">
      <OrderCreator       
      @closeOrderCreator="showOrderCreator = false"
      ></OrderCreator>
    </transition>
    -->
</template>
<script>
import { Order } from '@/classes/order/Order'
import { mdiClose } from '@mdi/js'
import CustomerInfo from './sales/CustomerInfo.vue'
import OrderCreator from './sales/OrderCreator.vue'
import OrderList from './sales/OrderList.vue'
export default {
  props: ['loading', 'orders', 'customer'],
  data() {
    return {
      selectedTab: null,
      showOrderCreator: false,
      abortController: null,
      selectedOrder: null,
      icons: {
        mdiClose,
      },
    }
  },
  methods: {
    callOrderCreator() {
      const conversation = this.$attrs.value

      this.selectedOrder = new Order({
        salechannel: conversation.salechannel,
        warehouse: conversation.warehouse,
        sns_ref: conversation.ownerId,
        sns_name: conversation.ownerName,
      })

      this.showOrderCreator = true
    },
    closeOrderCreator() {
      // this.getConversationMetadata(this.$attrs.value.id)
      this.showOrderCreator = false
    },
    editOrder(orderIndex) {
      const order = this.orders[orderIndex]

      this.selectedOrder = order

      this.showOrderCreator = true
    },
  },
  components: {
    OrderList,
    OrderCreator,
    CustomerInfo,
  },
}
</script>
<style lang="scss" scoped>
.sale-tabs::v-deep .v-tabs-bar {
  // your custom css properties
  height: 100%;
}

.v-tabs-items::v-deep .v-window__container {
  // your custom css properties
  height: 100%;
}

.v-tabs-items::v-deep .v-window__container > .v-window-item--active {
  height: 100%;
}

.slide-in-left-enter-active,
.slide-in-left-leave-active {
  transition: all 0.5s;
}

.slide-in-left-enter, .slide-in-left-leave-to /* .slide-in-left-leave-active in <2.1.8 */ {
  transform: translateX(100%);
}
</style>