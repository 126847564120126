<template>
    <div>
        <div>
            <div class="d-flex pa-4">
                <v-text-field
                    rounded
                    outlined
                    placeholder="ค้นหาชื่อ อีเมล เบอร์โทร"
                    :prepend-inner-icon="icons.mdiMagnify"
                    dense
                    clearable
                    @input="updateValue('textSearch', $event)"
                    :value="$attrs.value.textSearch"
                    hide-details=""
                ></v-text-field>
                <v-btn 
                icon
                @click="showFilter = !showFilter"
                ><v-icon
                :class="[{ 'error--text': filterRed }]"
                >{{ icons.mdiFilterOutline }}</v-icon></v-btn>
                <v-btn 
                v-if="closeable"
                icon
                @click="$emit('close')"
                ><v-icon                
                >{{ icons.mdiClose }}</v-icon></v-btn>
            </div>
            <v-slide-y-transition :hide-on-leave="true">
            <div :style="{'height' : '100%'}" class="pa-4" v-show="showFilter">
                <delay-autocomplete
                  outlined
                  cache-items
                  :value="$attrs.value.tags"
                  @input="updateValue('tags', $event)"
                  placeholder="พิมพ์เพื่อค้นหาแท็ก"
                  label="กรองตามแท็ก"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/chat/tagList'"                  
                  :lazySearch="true"                                    
                  item-text="name"
                  item-value="id"                                    
                  chips
                  clearable
                  deletable-chips
                  multiple
                  background-color="white"
                ></delay-autocomplete>

                <delay-autocomplete
                  outlined
                  :value="$attrs.value.channels"
                  @input="updateValue('channels', $event)"
                  placeholder="พิมพ์เพื่อค้นหาช่องทาง"
                  label="ตามช่องทาง"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/chat/channelList'"                  
                  :lazySearch="true"                                    
                  item-text="name"
                  item-value="id"                                    
                  chips
                  clearable
                  deletable-chips
                  multiple
                  background-color="white"
                >                  
                  <template v-slot:item="{ item, parent }">
                    <div class="d-flex justify-center align-center" style="position:relative">
                      <channel-logo 
                        :value="item"
                        class="white--text"                        
                      ></channel-logo>
                      <SNSAvartar
                        v-if="!!item.channel_type && item.channel_type != 'manual'"
                        :value="item.channel_type"
                        class="floating-avatar"
                        size="15"
                      ></SNSAvartar>
                    </div>
                    <div class="ml-4">
                      <div>{{ item.name }}</div>                    
                    </div>                            
                  </template>

                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close                      
                      @click:close="removeChannel(data.item)"                      
                    >
                      <div class="d-flex justify-center align-center" style="position:relative">
                        <channel-logo 
                          :value="data.item"
                          class="white--text"                          
                        ></channel-logo>
                        <SNSAvartar
                          right
                          v-if="!!data.item.channel_type && data.item.channel_type != 'manual'"
                          :value="data.item.channel_type"
                          class="floating-avatar"
                          style="width: 10px !important; height: 10px !important"
                        ></SNSAvartar>
                      </div>
                    </v-chip>
                  </template>
                </delay-autocomplete>

                <v-combobox
                  label="กรองตามพฤติกรรม"
                  outlined
                  clearable
                  multiple
                  chips
                  deletable-chips
                  :value="$attrs.value.behaviors"
                  @input="updateValue('behaviors', $event)"
                  :items="behaviors"
                  background-color="white"                  
                ></v-combobox>

                <div>
                  <v-btn
                  text
                  @click="clearAll"
                  class="text-decoration-underline"
                  large
                  >ล้างทั้งหมด</v-btn>
                </div>
            </div>
            </v-slide-y-transition>
        </div>
    </div>
</template>
<script>
import ChannelLogo from '@/components/ChannelLogo.vue'
import DelayAutocomplete from '@/components/DelayAutocomplete.vue'
import SNSAvartar from '@/components/SNSAvartar.vue'
import { mdiClose, mdiFilterOutline, mdiMagnify } from '@mdi/js'
export default {
  props: {
    closeable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showFilter: false,
      icons: {
        mdiFilterOutline,
        mdiMagnify,
        mdiClose,
      },
      behaviors: [
        {
          value: 'ghost',
          text: 'ทักแล้วไม่ตอบ',
        },
        {
          value: 'acquaintance',
          text: 'สนใจสินค้า/บริการ',
        },
        {
          value: 'unpaid',
          text: 'เปิดบิลแล้ว ยังไม่โอน',
        },
        {
          value: 'paid',
          text: 'ซื้อแล้ว',
        },
        {
          value: 'regular',
          text: 'ลูกค้าประจำ',
        },
      ],
    }
  },
  methods: {
    removeChannel(item) {
      const value = this.$attrs.value

      const { channels } = value

      const find = channels.findIndex(element => element == item.id)

      if (find < 0) return

      channels.splice(find, 1)

      this.updateValue('channels', channels)
    },
    clearAll() {
      this.$emit('input', {
        textSearch: null,
        channels: [],
        tags: [],
        behaviors: [],
      })
    },
    updateValue(field, value) {
      const newValue = {
        ...this.$attrs.value,
      }

      newValue[field] = value

      this.$emit('input', newValue)
    },
  },
  computed: {
    filterRed() {
      const { textSearch, channels, tags, behaviors } = this.$attrs.value

      if (!!textSearch) return true

      if (!!channels) return true

      if (!!tags) return true

      if (!!behaviors) return true

      return false
    },
  },
  components: {
    DelayAutocomplete,
    ChannelLogo,
    SNSAvartar,
  },
}
</script>