import { Message } from "./Message";

export class MessageGroup
{
    _messages
    #sender
    #timestamp
    #metadata    

    constructor({ message, sender, timestamp }) {

        const { metadata } = message

        this.#timestamp = timestamp
        this.#sender = sender        
        
        if (!!metadata)
            this.#metadata = JSON.parse(metadata)        

        // this._messages = [new Message(message, timestamp)]
        this._messages = []
    }

    get senderId() {
        if (!this.#sender) return ''
        
        return this.#sender.id
    }    

    get messages() {
        return this._messages
    }
    
    get isSendByAdmin() {
        if (!this.#metadata) return false

        return true
    }

    get adminId() {
        if (!this.isSendByAdmin) return null

        return this.#metadata.id
    }

    get adminName() {
        if (!this.isSendByAdmin) return null

        return this.#metadata.name
    }

    get adminProfile() {
        if (!this.isSendByAdmin) return null

        return this.#metadata.profile
    }

    get timestamp() {
        return this.#timestamp
    }    

    get metadata() {
        return this.#metadata
    }

    set timestamp(value) {
        this.#timestamp = value
    }

    isSameGroup({ sender, timestamp, message }) {
        
        const { attachments, metadata, reply_to, sticker } = message

        // if (!!sticker) return false

        // if (!!attachments) return false

        if (!!reply_to) return false
        
        if (sender.id != this.senderId) return false

        if (this.#timestamp - timestamp >= 10800000) return false
        

        if (!!metadata && !!this.#metadata) {
            const jsonMetadata = JSON.parse(metadata)

            if (jsonMetadata.id != this.#metadata.id) return false
        }

        return true
    }
    
    addMessage(rawMessage) {
        const { timestamp, message } = rawMessage

        // Commented code below should be used to verify before add text to group
        // But it waste some performance so better comment
        // if (!this.isSameGroup(rawMessage)) return;
        
        if (this.#timestamp < timestamp)
            this.#timestamp = timestamp        

        this._messages.unshift(new Message(message, timestamp))
    }
    
    pushMessage(rawMessage) {
        const { timestamp, message } = rawMessage
        
        this.#timestamp = timestamp            

        const messageObj = new Message(message, timestamp)

        this._messages.push(messageObj)

        return messageObj
    }
}