import { MessageGroup } from "../MessageGroup";
import { LineMessage } from "./LineMessage";

export class LineMessageGroup extends MessageGroup
{
    #source

    constructor(rawMessage) {
        super(rawMessage)

        const { source } = rawMessage

        this.#source = source
    }


    get senderId() {
        if (!this.#source) return ''

        if (this.#source.type == 'aoo') return this.refID
        
        return this.#source.userId
    }    

    addMessage(rawMessage) {
        const { _id, timestamp, message } = rawMessage

        // Commented code below should be used to verify before add text to group
        // But it waste some performance so better comment
        // if (!this.isSameGroup(rawMessage)) return;
        
        if (this.timestamp < timestamp)
            this.timestamp = timestamp                    

        this._messages.unshift(new LineMessage(_id, message, timestamp))
    }
    
    pushMessage(rawMessage) {
        const { _id, timestamp, message } = rawMessage
        
        this.timestamp = timestamp            

        const messageObj = new LineMessage(_id, message, timestamp)

        this._messages.push(messageObj)

        return messageObj
    }

    isSameGroup({ source, timestamp, message }) {
        
        const { metadata, reply_to } = message


        if (!!reply_to) return false
        
        if (source.type != this.#source.type) return false

        if (this.timestamp - timestamp >= 10800000) return false
        

        if (!!metadata && !!this.metadata) {
            const jsonMetadata = JSON.parse(metadata)

            if (jsonMetadata.id != this.metadata.id) return false
        }

        return true
    }
}