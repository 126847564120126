import { Conversation } from "../Conversation";
import { LineDateGroup } from './LineDateGroup';

export class LineConversation extends Conversation
{
    get replyable() {
        const now = new Date();
        // LIMIT of Line is 7 days
        return !(now.getTime() - this.timestamp > 7 * 86400000)
    }

    /**
     * @param {{ text: any; }} message
     */
    set snippet(message) {
        let text;
        if (message.type == 'text')
            text = message.text
        else if (message.type == 'image')
            text = 'ส่งรูป';
        else if (message.type == 'video')
            text = 'ส่งวีดีโอ';
        else if (message.type == 'audio')
            text = 'ส่งเสียง';
        else if (message.type == 'file')
            text = 'ไฟล์';
        else if (message.type == 'location')
            text = 'ส่งโลเคชั่น';
        else if (message.type == 'sticker')
            text = 'ส่ง Sticker';
        else {
            text = 'ส่งข้อมูลไม่ทราบรูปแบบ'
        }

        super.snippet = {
            text: text
        }
    }
    
    get snippet() {
        return super.snippet
    }

    _getDateGroupByTimestamp(timestamp) {
        /*
        let dateGroup = this.#dateGroups.findLast(item => item.isSameGroup(timestamp))
        if (!dateGroup) {
            dateGroup = new DateGroup(timestamp)            
            this.#dateGroups.push(dateGroup)
        }
        */

        let latestDateGroup = this._getTopDateGroup()

        if (!latestDateGroup || !latestDateGroup.isSameGroup(timestamp)) {
            latestDateGroup = new LineDateGroup(timestamp)
            this.dateGroups.unshift(latestDateGroup)
        }        

        return latestDateGroup
    }
    

    addMessage(rawMessage) {        
        // For adding messages fetch from server
        const { timestamp, message } = rawMessage              
        
        const dateGroup = this._getDateGroupByTimestamp(timestamp)

        dateGroup.addMessage(rawMessage)            
    }

    pushMessage(rawMessage) {        
        // For push new messages from webhook

        const { _id, timestamp, message } = rawMessage              

        if (timestamp > this.timestamp) {
            this.snippet = message
            this.timestamp = timestamp
        }

        const existingMessage = this._getMessageByMID(message.id)

        if (!!existingMessage) {
            existingMessage.markSent(_id, message, timestamp)
            return existingMessage
        }
        
        let dateGroup = this._getLatestDateGroup()

        if (!dateGroup || !dateGroup.isSameGroup(timestamp)) {            
            dateGroup = new LineDateGroup(timestamp)
            this.dateGroups.push(dateGroup)
        }        

        return dateGroup.pushMessage(rawMessage)
    }

    pushTmpMessage(text, metadata) {
        const now = new Date()

        const rawMessage = {
            type: 'message',
            timestamp: now.getTime(),
            source: {
                type: 'aoo',
                userId: this.ownerId
            },
            message: {
                is_tmp: true,
                id: 'TEMP_' + now.getTime(),
                type: 'text',
                text: text,
                metadata: metadata,
            },
        }        

        return this.pushMessage(rawMessage)
    }
}