<template>
    <div :class="['py-2', 'px-4', 'base-text', 'elevation-1', {'base-color': isOwner, 'owner-color': !isOwner}]" elevation="1">      
      <div v-if="$attrs.value.template">
        <!-- TEMPLATE FOR LINE -->
        <h3 class="base-text">{{ $attrs.value.template.title }}</h3>
        {{ $attrs.value.text }}

        <div v-if="$attrs.value.template.type == 'buttons'" class="d-flex flex-column">
          <v-btn 
          v-for="(button, index) in $attrs.value.template.actions"
          :key="index"
          @click="button.type == 'uri' ? goToURL(button.uri) : null"
          >
              {{ button.label }}
          </v-btn>
        </div>
      </div>
      <div v-else-if="$attrs.value.attachments">
        <!-- TEMPLATE FOR FACEBOOK -->
        {{ $attrs.value.text }}

        <div v-if="$attrs.value.attachments[0].payload.template_type == 'button'" class="d-flex flex-column">
          <v-btn 
          v-for="(button, index) in $attrs.value.attachments[0].payload.buttons"
          :key="index"
          @click="button.type == 'web_url' ? goToURL(button.url) : null"
          >
              {{ button.title }}
          </v-btn>
        </div>
      </div>            
    </div>
</template>
<script>
export default {
  props: ['isOwner'],
  methods: {
    goToURL(url) {
      window.open(url, '_blank').focus()
    },
  },
}
</script>
<style scoped>
.base-text {
  word-break: break-all;
  white-space: pre-line;
  max-width: 100%;
  box-shadow: 0 1px 3px 0 rgba(94, 86, 105, 0.2), 0 2px 1px -1px rgba(94, 86, 105, 0.12),
    0 1px 1px 0 rgba(94, 86, 105, 0.14);
}

.base-color {
  background: #fff;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.owner-color {
  background-color: #ff8181;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
</style>