<template>
  <div class="chat-image-slider mt-2 d-flex flex-wrap"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-img v-for="(img, index) in media" :key="index" 
      :src="img.src"
      :lazy-src="img.thumb"
      max-height="100"
      max-width="100"      
      @click="showLightBox(index)"
    />  
    <LightBox 
      :media="media"        
      :showLightBox="false"
      siteLoading="ไม่พบรูปภาพ"
      ref="lightbox"
    ></LightBox>    
  </div>
</template>
<script>
import LightBox from 'vue-image-lightbox'

export default {
  computed: {
    media() {
      if (!this.$attrs.value || this.$attrs.value.length == 0) return []

      return this.$attrs.value.map(item => {
        return {
          thumb: item.payload.url,
          src: item.payload.url,
        }
      })
    },
  },
  methods: {
    showLightBox(imageIndex) {
      this.$refs.lightbox.showImage(imageIndex)
    },
  },
  components: {
    LightBox,
  },
}
</script>
<style scoped>
.chat-image-slider {
  max-width: 80%;
  flex-wrap: 1;
}
</style>