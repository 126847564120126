import { render, staticRenderFns } from "./ChatList.vue?vue&type=template&id=34de24ce&scoped=true&"
import script from "./ChatList.vue?vue&type=script&lang=js&"
export * from "./ChatList.vue?vue&type=script&lang=js&"
import style0 from "./ChatList.vue?vue&type=style&index=0&id=34de24ce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34de24ce",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VListItem,VListItemAction,VListItemActionText,VListItemAvatar,VListItemContent,VListItemGroup,VListItemSubtitle,VListItemTitle,VOverlay,VProgressCircular})
