
import DefaultProfileImage from '@/assets/images/avatars/1.png';
import { timestampToShortText } from "@/helpers/dateTimeConverter";
import { DateGroup } from './DateGroup';

export class Conversation
{
    #id;
    #refID;
    #unreadCount = 0;
    /*
    #channelId;
    #channelName;
    #profile;
    #type;
    */
    #timestamp;    
    #owner;
    #snippet;
    #dateGroups = [];
    #tmpMessages = [];
    #salechannel;

    //constructor(id, refID, timestamp, unreadCount, snippet, channelId, channelName, channelProfile, type, owner) {
    constructor(id, refID, timestamp, unreadCount, snippet, salechannel, owner) {
        this.#id = id
        this.#refID = refID
        this.#unreadCount = unreadCount
        this.#timestamp = timestamp
        /*
        this.#channelId = channelId
        this.#channelName = channelName
        this.#profile = channelProfile
        this.#type = type
        */        
        this.#salechannel = salechannel        
        this.#snippet = snippet
        this.#owner = owner
    }

    //#region getter setter

    get id() {
        return this.#id
    }

    get refID() {
        return this.#refID
    }

    get unreadCount() {
        return this.#unreadCount
    }

    get type() {
        return this.#salechannel.channel_type
    }

    get channelId() {
        return this.#salechannel.id
    }

    get channelName() {
        return this.#salechannel.name
    }

    get salechannelId() {
        return this.channelId
    }

    get salechannelName() {
        return this.channelName
    }

    get salechannel() {
        return this.#salechannel
    }

    get defaultWarehouse() {
        if  (!this.#salechannel.warehouse) return null

        return this.#salechannel.warehouse
    }

    get warehouse() {
        return this.defaultWarehouse
    }

    get profile() {
        return this.#salechannel.profile
    }

    get snippet() {
        return this.#snippet
    }

    get ownerId() {
        return this.#owner.id
    }

    get ownerName() {
        return !!this.#owner.name ? this.#owner.name : this.#owner.first_name + ' ' + this.#owner.last_name
    }

    get ownerProfile() {
        return !!this.#owner.profile ? this.#owner.profile : DefaultProfileImage
    }    

    get ownerUsername() {
        if (this.#owner.username) return this.#owner.username

        return null
    }

    get time() {
        return timestampToShortText(this.#timestamp)        
    }

    get timestamp() {
        return this.#timestamp
    }

    set timestamp(timestamp) {
        this.#timestamp = timestamp
    }

    get dateGroups() {
        return this.#dateGroups
    }

    get tmpMessages() {
        return this.#tmpMessages
    }

    get replyable() {
        const now = new Date();

        return !(now.getTime() - this.#timestamp > 7 * 86400000)
    }

    set snippet(message) {
        if (message.hasOwnProperty('text') && !message.attachments)
            this.#snippet = message.text
        else if (message.sticker) {
            this.#snippet = 'ส่ง Sticker'
        }
        else {            
            var length = message.attachments.length;
            if (message.attachments[length - 1].type != "fallback")
                this.#snippet = 'ส่ง ' + message.attachments[length - 1].type;
            else if (message.text)
                this.#snippet = message.text
            else {
                console.log('wrong format')
                console.log(message)                
            }
        }
    }

    //#endregion

    _getTopDateGroup() {
        if (this.#dateGroups.length == 0) return null

        return this.#dateGroups[0]
    }

    _getLatestDateGroup() {
        return this.#dateGroups[this.#dateGroups.length - 1]
    }

    _getDateGroupByTimestamp(timestamp) {
        /*
        let dateGroup = this.#dateGroups.findLast(item => item.isSameGroup(timestamp))
        if (!dateGroup) {
            dateGroup = new DateGroup(timestamp)            
            this.#dateGroups.push(dateGroup)
        }
        */

        let latestDateGroup = this._getTopDateGroup()

        if (!latestDateGroup || !latestDateGroup.isSameGroup(timestamp)) {
            latestDateGroup = new DateGroup(timestamp)
            this.#dateGroups.unshift(latestDateGroup)
        }        

        return latestDateGroup
    }

    _getMessageByMID(mid) {
        for (let i = this.#dateGroups.length - 1; i >= 0; i--) {
            const dateGroup = this.#dateGroups[i];

            const messageGroups = dateGroup.messageGroups

            for (let j = messageGroups.length - 1; j >=0; j--) {
                const messageGroup = messageGroups[j]

                const findMessage = messageGroup.messages.find(item => item.id == mid)

                if (findMessage) return findMessage
            }
        }

        return null
    }

    addMessage(rawMessage) {        
        // For adding messages fetch from server
        const { timestamp, message } = rawMessage              
        
        const dateGroup = this._getDateGroupByTimestamp(timestamp)

        dateGroup.addMessage(rawMessage)            
    }

    pushMessage(rawMessage) {
        // For push new messages from webhook

        const { timestamp, message } = rawMessage              

        if (timestamp > this.#timestamp) {
            this.snippet = message
            this.timestamp = timestamp
        }

        const existingMessage = this._getMessageByMID(message.mid)

        if (!!existingMessage) {
            existingMessage.markSent(message, timestamp)
            return existingMessage
        }
        
        let dateGroup = this._getLatestDateGroup()

        if (!dateGroup || !dateGroup.isSameGroup(timestamp)) {
            dateGroup = new DateGroup(timestamp)
            this.#dateGroups.push(dateGroup)
        }        

        return dateGroup.pushMessage(rawMessage)
    }

    pushTmpMessage(text, metadata) {
        const now = new Date()

        const rawMessage = {
            timestamp: now.getTime(),
            sender: {
                id: this.refID,
            },
            message: {
                is_tmp: true,
                mid: 'TEMP_' + now.getTime(),
                text: text,
                metadata: metadata,
            },
        }        

        return this.pushMessage(rawMessage)
    }

    clearMessages() {
        this.#dateGroups = []
    }

    read() {
        this.#unreadCount = 0
    }

    touch(timestamp, snippet) {
        this.#snippet = snippet
        if (this.#timestamp < timestamp)
            this.#timestamp = timestamp
        this.#unreadCount++
    }

    unsent(rawMessage, snippet) {

        const { message } = rawMessage              

        const existingMessage = this._getMessageByMID(message.mid)

        if (!existingMessage) return null

        existingMessage.markUnsent()

        this.#snippet = snippet    
    }
}