import { render, staticRenderFns } from "./ReplyTo.vue?vue&type=template&id=22ad8ddb&scoped=true&"
import script from "./ReplyTo.vue?vue&type=script&lang=js&"
export * from "./ReplyTo.vue?vue&type=script&lang=js&"
import style0 from "./ReplyTo.vue?vue&type=style&index=0&id=22ad8ddb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22ad8ddb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
