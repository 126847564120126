export class Message
{
    #id
    #text
    #timestamp
    #attachments
    #replyTo
    #sticker
    #name
    #is_tmp
    #isDeleted
    
    constructor(message, timestamp) {        
        // TODO : COMMENT SUPPORT ??        
        const { mid, text, attachments, reply_to, sticker, name, is_tmp, is_deleted } = message
        this.#id = mid        
        this.#text = text
        this.#sticker = sticker
        this.#attachments = attachments
        this.#name = name
        this.#is_tmp = is_tmp
        this.#isDeleted = is_deleted

        if (reply_to) {            
            this.#replyTo = new Message(reply_to, timestamp)   
        }

        this.#timestamp = timestamp
    }

    get id() {
        return this.#id
    }

    get mid() {
        return this.#id
    }

    get type() {
        if (!!this.#sticker) return 'sticker'

        if (!!this.#attachments) {            
            return this.#attachments[0].type
        }        

        return 'text'
    }

    get text() {
        return this.#text
    }

    get sticker() {
        return this.#sticker
    }

    get video() {
        if (!this.#attachments) return null

        if (!this.#attachments[0].payload) return null

        return this.#attachments[0].payload.url
    }

    get storyURL() {
        if (!this.#attachments) return null

        if (!this.#attachments[0].payload) return null

        return this.#attachments[0].payload.url
    }

    get isStoryExpired() {
        const now = new Date();        

        return (now.getTime() - this.#timestamp > 1 * 86400000)
    }

    get attachments() {
        return this.#attachments
    }

    get replyTo() {
        return this.#replyTo
    }

    get name() {
        return this.#name
    }

    get time() {
        let dateObject = new Date(this.#timestamp)
        
        return dateObject.getHours().toString().padStart(2, "0") + ":" + dateObject.getMinutes().toString().padStart(2, "0")
    }

    get isTmp() {
        return this.#is_tmp
    }

    get isDeleted() {
        return this.#isDeleted
    }

    set mid(id) {
        this.#id = id
    }

    set id(id) {
        this.#id = id
    }

    markSent({ mid, text, attachments, reply_to, sticker, name }, timestamp) {
        this.#is_tmp = false
        this.#id = mid        
        this.#text = text
        this.#sticker = sticker
        this.#attachments = attachments
        this.#name = name        

        if (reply_to) {            
            this.#replyTo = new Message(reply_to, timestamp)   
        }

        this.#timestamp = timestamp
    }

    markUnsent() {
        this.#isDeleted = true
        this.#text = "<<ข้อความถูกลบ>>"
        this.#sticker = null
        this.#attachments = null
    }
}