import { DateGroup } from "../DateGroup";
import { LineMessageGroup } from "./LineMessageGroup";

export class LineDateGroup extends DateGroup
{
    /*
    #source

    get senderId() {
        this.#source.userId
    }

    constructor(rawMessage) {
        super(rawMessage)        
        
        const { source } = rawMessage

        this.#source = source
    }
    */

    #getMessageGroup(rawMessage) {
        // Will create new message group in the following conditions
        // 1. diff sender (also between diff admins)
        // 2. timestamp diff more than 600000 ms
        // 3. message is an attachment
        // 4. message is a reply message

        let latestGroup = this._getTopMessageGroup()

        if (!latestGroup || !latestGroup.isSameGroup(rawMessage)) {
            latestGroup = new LineMessageGroup(rawMessage)
            this.messageGroups.unshift(latestGroup)
        } 

        return latestGroup
    }

    addMessage(rawMessage) {        
        const { timestamp } = rawMessage

        if (!this.isSameGroup(timestamp)) return;

        if (this.timestamp > timestamp)
            this.timestamp = timestamp

        const messageGroup = this.#getMessageGroup(rawMessage)

        messageGroup.addMessage(rawMessage)        
    }

    pushMessage(rawMessage) {                        
        let latestGroup = this._getLatestMessageGroup()
        if (!latestGroup || !latestGroup.isSameGroup(rawMessage)) {
            latestGroup = new LineMessageGroup(rawMessage)
            this.messageGroups.push(latestGroup)
        }

        return latestGroup.pushMessage(rawMessage)
    }
}