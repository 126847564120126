<template>
    <div :class="['d-flex', 'flex-grow-1', 'mb-8', { 'flex-row-reverse': !isOwner }]">
      <div class="d-flex justify-center px-4">
        <v-avatar
        size="38"
        >
          <img 
          v-if="isOwner"
          :src="conversation.ownerProfile" 
          @error="handleErrorImage">          
          <channel-logo 
            v-else
            :value="{
              name: conversation.channelName,
              channel_type: conversation.type,
              profile: $attrs.value.isSendByAdmin ? $attrs.value.adminProfile : conversation.profile
            }"
            class="white--text"                                    
            size="38"
          ></channel-logo>          
        </v-avatar>
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <template
          v-for="(message, messageIndex) in $attrs.value.messages"        
        >
          <v-tooltip 
            :left="!isOwner"
            :right="isOwner"
            :key="'message_' + message.mid"
          >
            <template v-slot:activator="{ on, attrs }">
              <reply-to
                v-if="!!message.replyTo"
                :firstGroup="firstGroup && messageIndex == $attrs.value.messages.length - 1"              
                :isOwner="isOwner"
                :value="message"
                v-bind="attrs"
                v-on="on"
                :class="['mb-2', 'base-message',{'mr-auto': isOwner, 'ml-auto': !isOwner, 'first': firstGroup, 'left-message': isOwner, 'right-message': !isOwner}]"
              />
              <line-sticker
                v-else-if="!!message.stickerId && message.type == 'sticker'"
                :stickerId="message.stickerId"
              ></line-sticker>
              <message
                v-else
                :firstGroup="firstGroup && messageIndex == $attrs.value.messages.length - 1"              
                :isOwner="isOwner"
                :value="message"
                v-bind="attrs"
                v-on="on"
                :class="['mb-2', 'base-message',{'mr-auto': isOwner, 'ml-auto': !isOwner, 'white--text': !isOwner, 'first': firstGroup, 'left-message': isOwner, 'right-message': !isOwner}]"
              />
            </template>            
            <span class="text-xs">{{ message.time }}</span>
          </v-tooltip>
          <div
            v-if="message.isTmp"
            class="text-right"
          >
          <v-progress-circular
            indeterminate
            color="primary"
            size="16"
          ></v-progress-circular>
          กำลังส่ง. . .</div>          
          <div           
          v-else
          :class="['d-flex', 'flex-row','text-xs', 'text--disabled', {'justify-end': !isOwner}]">                    
            <div v-if="$attrs.value.isSendByAdmin && messageIndex == $attrs.value.messages.length - 1">ส่งโดย {{ $attrs.value.adminName }}</div>
            <template v-if="messageIndex == $attrs.value.messages.length - 1 || $attrs.value.messages[messageIndex + 1].isTmp">
              <div class="read-container success--text" v-if="!isOwner">
                <v-icon size="16">{{ icons.mdiCheck }}</v-icon>            
              </div>
              <div>{{ $attrs.value.messages[$attrs.value.messages.length -1].time }}</div>          
            </template>            
          </div>
        </template>                
      </div>      
    </div>
</template>
<script>
import DefaultProfileImage from '@/assets/images/avatars/1.png'
import ChannelLogo from '@/components/ChannelLogo.vue'
import { mdiCheck } from '@mdi/js'
import LineSticker from './LineSticker.vue'
import Message from './Message.vue'
import ReplyTo from './ReplyTo.vue'
export default {
  props: ['conversation', 'firstGroup'],
  data() {
    return {
      icons: {
        mdiCheck,
      },
    }
  },
  computed: {
    isOwner() {
      return this.conversation.ownerId == this.$attrs.value.senderId
    },
    isFirstMessage() {},
  },
  methods: {
    async handleErrorImage(event) {
      if (event.target) event.target.src = DefaultProfileImage
    },
  },
  components: {
    Message,
    ChannelLogo,
    ReplyTo,
    LineSticker,
  },
}
</script>
<style scoped>
.read-container {
  position: relative;
  width: 30px;
}

.read-container > span {
  position: absolute;
}

.read-container > span:first {
  left: 0;
}

.read-container > span:last-of-type {
  left: 6px;
}

.base-message {
  max-width: 80%;
}

.left-message {
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.right-message {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
</style>