<template>
    <div :class="['py-2', 'px-4', 'base-text', 'elevation-1', {'base-color': isOwner, 'owner-color': !isOwner}]" elevation="1">
      {{ $attrs.value }}
    </div>
</template>
<script>
export default {
  props: ['isOwner'],
}
</script>
<style scoped>
.base-text {
  word-break: break-all;
  white-space: pre-line;
  max-width: 100%;
  box-shadow: 0 1px 3px 0 rgba(94, 86, 105, 0.2), 0 2px 1px -1px rgba(94, 86, 105, 0.12),
    0 1px 1px 0 rgba(94, 86, 105, 0.14);
}

.base-color {
  background: #fff;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.owner-color {
  background-color: #ff8181;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
</style>