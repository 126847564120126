<template>
    <div
      v-bind="$attrs"
      v-on="$listeners"
      class="d-flex flex-column"
    >      
      <div><v-icon>{{ icons.mdiReply }}</v-icon> <span class="text-subtitle-2">ตอบกลับ {{ $attrs.value.replyTo.name }}</span></div>
      <div
      :class="[{'mr-auto': isOwner, 'ml-auto': !isOwner}]"
      >      
        <Message        
          :isOwner="isOwner"
          :value="$attrs.value.replyTo"
          :class="['d-flex', 'chat-reply-text', {'white--text': !isOwner}]"          
        />        
      </div>
      <Message        
        :isOwner="isOwner"
        :value="$attrs.value"
        :class="[{'white--text': !isOwner, 'mr-auto': isOwner, 'ml-auto': !isOwner}]"
      />
    </div>
</template>
<script>
import Message from './Message.vue'
import { mdiReply } from '@mdi/js'
export default {
  name: 'ReplyComponent',
  props: ['isOwner', 'firstGroup'],
  data() {
    return {
      icons: {
        mdiReply,
      },
    }
  },
  components: {
    Message,
  },
}
</script>
<style scoped>
.chat-reply-text {
  word-break: break-all;
  white-space: pre-line;
  background: #f4f4f4;
  opacity: 0.4;
  flex-basis: 100%;
}
</style>