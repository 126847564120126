<template>
    <div     
    v-bind="$attrs"
    v-on="$listeners"
    >
        <normal-text
        v-if="$attrs.value.type == 'text'"
        :value="$attrs.value.text"
        :isOwner="isOwner"
        />
        <s-n-s-template
        v-else-if="$attrs.value.type == 'template'"
        :value="$attrs.value"
        :isOwner="isOwner"
        />
        <image-slider
        v-else-if="$attrs.value.type == 'image' && !!$attrs.value.attachments"                 
        :value="$attrs.value.attachments"        
        />
        <single-image
        v-else-if="$attrs.value.type == 'image' && !!$attrs.value.contentURL"
        :src="$attrs.value.contentURL"
        :preview="$attrs.value.previewURL"
        ></single-image>
        <no-image
        v-else-if="$attrs.value.type == 'image' && !$attrs.value.contentURL"
        :value="$attrs.value._id"
        ></no-image>
        <v-img
        v-else-if="$attrs.value.type == 'sticker'"                 
        :src="$attrs.value.sticker"                 
        max-height="100"
        max-width="100"
        min-height="100"        
        />
        <video v-else-if="$attrs.value.type == 'video'"               
        :src="$attrs.value.video"
        controls
        style="max-width: 200px;"
        ></video>
        <story-mention
        v-else-if="$attrs.value.type == 'story_mention'"
        :value="$attrs.value"
        ></story-mention>
        <!--
        <facebook-template v-else-if="$attrs.value.type == 'template'"
        ></facebook-template>
        -->
        <div
        v-else
        class="black--text"
        >&lt; {{ $attrs.value.type }} IS CURRENTLY UNSUPPORT &gt;</div>
    </div>
</template>
<script>
import ChannelLogo from '@/components/ChannelLogo.vue'
import FacebookTemplate from './FacebookTemplate.vue'
import ImageSlider from './ImageSlider.vue'
import NoImage from './NoImage.vue'
import NormalText from './NormalText.vue'
import SNSTemplate from './SNSTemplate.vue'
import SingleImage from './SingleImage.vue'
import StoryMention from './StoryMention.vue'
export default {
  name: 'MessageComponent',
  props: ['isOwner', 'firstGroup', 'isRecursive'],
  components: {
    NormalText,
    ChannelLogo,
    ImageSlider,
    FacebookTemplate,
    SingleImage,
    NoImage,
    SNSTemplate,
    StoryMention,
  },
}
</script>