<template>
  <div class="chat-image-slider mt-2 d-flex flex-wrap"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-img
      :src="src"
      :lazy-src="preview"
      max-height="100"
      max-width="100"      
      @click="showLightBox()"
    />
    <LightBox 
      :media="[
        {
          thumb: preview,
          src: src
        }
      ]"        
      :showLightBox="false"
      siteLoading="ไม่พบรูปภาพ"
      ref="lightbox"
    ></LightBox>
  </div>
</template>
<script>
import LightBox from 'vue-image-lightbox'

export default {
  props: ['src', 'preview'],
  computed: {},
  methods: {
    showLightBox() {
      this.$refs.lightbox.showImage(0)
    },
  },
  components: {
    LightBox,
  },
}
</script>
<style scoped>
.chat-image-slider {
  max-width: 80%;
  flex-wrap: 1;
}
</style>