<template>
    <div v-if="$attrs.value" style="overflow:hidden; position: relative; height: 100%" class="d-flex flex-grow-1 flex-column">
      <div class="pt-4 pb-2 px-6" style="border-bottom: 1px solid #a4a4a4;">
        <div><v-btn icon v-if="!$vuetify.breakpoint.mdAndUp" @click="$emit('openSidebar')"><v-icon>{{ icons.mdiMenu }}</v-icon></v-btn> {{ $attrs.value.ownerName }} {{ $attrs.value.ownerUsername ? "(" + $attrs.value.ownerUsername + ")" : '' }}</div>
        <div class="mt-2">
          <customer-tag 
          :value="!!tags ? tags : []"    
          @addTag="$emit('addTag', $event)"      
          @removeTag="$emit('removeTag', $event)"
          :isAddable="true"
          ></customer-tag>
        </div>
      </div>
      <message-container 
        :value="dateGroups"        
        :conversation="$attrs.value"
        :messageLoading="messageLoading"
        :messageError="messageError"
        ref="messageContainer"
        @loadNextPage="loadNextPage"
      />
      <chat-reply 
        v-if="$attrs.value.replyable"
        :conversation="$attrs.value"
        @sendMessage="sendMessage"
        @sendResponse="sendResponse"
      />
      <div
        v-else
        class="text-center"
        style="padding: 10px 20px;"
      >{{ warningText }}</div>      
      <v-fab-transition>
        <v-btn            
          color="primary"
          dark
          fixed
          bottom
          right
          fab
          style="bottom: 140px;"
          @click="$emit('openChatSale')"
          v-if="!$vuetify.breakpoint.mdAndUp"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
</template>
<script>
import { LineConversation } from '@/classes/chat/line/LineConversation'
import { asyncDelete, asyncGet, asyncPost, asyncPostAsJson } from '@/helpers/asyncAxios'
import errorParser from '@/plugins/errorparser'
import { mdiMenu, mdiPlus } from '@mdi/js'
import axios from 'axios'
import ChatReply from './ChatReply.vue'
import CustomerTag from './CustomerTag.vue'
import MessageContainer from './messages/MessageContainer.vue'
export default {
  props: ['tags'],
  data() {
    return {
      currentPage: 1,
      messageLoading: false,
      messageError: false,
      nextPagination: null,
      fetchTimer: null,
      dateGroups: [],
      abortController: null,
      icons: {
        mdiPlus,
        mdiMenu,
      },
    }
  },
  watch: {
    '$attrs.value': function (value, oldValue) {
      if (value == oldValue) return

      this.dateGroups = []

      if (this.fetchTimer) clearTimeout(this.fetchTimer)

      if (!value) return

      // this.fetchTimer = setTimeout(this.getMessage, 250, value.id, 1)
      this.getMessage(value.id, 1)

      this.$emit('refreshMetadata')
    },
  },
  created() {
    this.getMessage(this.$attrs.value.id, 1)

    this.$emit('refreshMetadata')
  },
  computed: {
    warningText() {
      if (this.$attrs.value instanceof LineConversation)
        return 'ไม่สามารถตอบของความนี้ผ่านช่องทางนี้ได้ ต้องตอบผ่าน LineOA เท่านั้น (เป็นนโยบายของทาง Line)'
      return 'ไม่สามารถตอบของความนี้ผ่านช่องทางนี้ได้ ต้องตอบผ่านเพจเท่านั้น (เป็นนโยบายของทาง facebook)'
    },
  },
  methods: {
    refreshMessage() {
      const conversation = this.$attrs.value

      this.dateGroups = [...conversation.dateGroups]

      this.scrollToBottomSmooth()
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          const ref = this.$refs.messageContainer

          if (ref) {
            ref.scrollToBottom()
          }
        })
      })
    },
    scrollToBottomSmooth() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          const el = this.$refs.messageContainer.$el

          if (!el) return

          el.scrollTo({ left: 0, top: el.scrollHeight + 50, behavior: 'smooth' })
        })
      })
    },
    async getMessage(conversation_id, page = 1) {
      this.messageLoading = true
      this.messageError = false

      const conversation = this.$attrs.value

      if (page == 1) conversation.clearMessages()

      if (this.abortController) {
        this.abortController.abort()
      }

      this.abortController = new AbortController()

      this.currentPage = page
      try {
        const { data, pagination } = await asyncGet(
          '/chat/conversations/' + conversation_id + '/messages',
          {
            page: page,
          },
          {
            signal: this.abortController.signal,
          },
        )

        data.forEach(rawMessage => {
          conversation.addMessage(rawMessage)
        })

        this.nextPagination = !!pagination.next ? pagination.next : null
      } catch (error) {
        if (axios.isCancel(error)) {
          this.messageError = true
          console.log(error)
          return
        }
        //if (axios.isCancel(error)) return
      } finally {
        this.messageLoading = false
      }

      // setInterval(this.addMessage, 3000)

      this.dateGroups = conversation.dateGroups

      if (page == 1) this.scrollToBottom()

      // TODO : Save Scroll Position and return in case it's not
    },
    loadNextPage(event) {
      if (this.messageLoading) return

      if (this.nextPagination == null) return

      this.getMessage(this.$attrs.value.id, this.nextPagination)
    },
    async sendMessage({ text, files, saved_id }) {
      /*
        TODO : catch error message

        it will contain error in response, but status = 0

      */

      if (!(this.$attrs.value instanceof LineConversation)) {
        const metadata = JSON.stringify({
          name: this.$store.getters['auth/GET_USER_FULLNAME'],
          profile: this.$store.getters['auth/GET_USER_IMAGE'],
        })

        const message = this.$attrs.value.pushTmpMessage(text, metadata)

        this.refreshMessage()

        try {
          const { status, msg } = await asyncPostAsJson('/chat/conversations/' + this.$attrs.value.id + '/messages', {
            files: files,
            text: text,
            saved_id: saved_id,
          })

          if (status != 0) throw msg

          const ret = msg[0]

          message.id = !!ret.message_id ? ret.message_id : ret.id

          this.refreshMessage()
        } catch (error) {
          console.log(error)
          this.$root.showCommonDialog('มีปัญหา', errorParser(error))
        }
      } else {
        try {
          const { status, msg } = await asyncPostAsJson('/chat/conversations/' + this.$attrs.value.id + '/messages', {
            files: files,
            text: text,
            saved_id: saved_id,
          })

          if (status != 0) throw msg

          this.refreshMessage()
        } catch (error) {
          console.log(error)
          this.$root.showCommonDialog('มีปัญหา', errorParser(error))
        }
      }
    },
    async sendResponse(id) {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      try {
        await asyncPostAsJson('/chat/conversations/' + this.$attrs.value.id + '/sendSavedResponse', {
          response_id: id,
        })

        this.refreshMessage()
      } catch (error) {
        console.log(error)
      }
      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    async addTag(name) {
      await asyncPost('/chat/' + $attrs.value.id + '/tag', { tag: name })
      this.$emit('refreshMetadata')
    },
    async removeTag(name) {
      await asyncDelete('/chat/' + $attrs.value.id + '/tag', { tag: name })
      this.$emit('refreshMetadata')
    },
  },
  components: {
    MessageContainer,
    ChatReply,
    CustomerTag,
  },
}
</script>