<template>
    <div></div>
</template>
<script>
export default {
  data() {
    return {}
  },
  created() {
    return $attrs.value
  },
}
</script>