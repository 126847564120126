<template>
    <div class="d-flex flex-column message-container-wrapper" @scroll="onScroll">
        <div class="py-4" v-if="messageLoading">
          <div class="text-center">
            กำลังโหลด...
          </div>
          <div class="text-center">
            <v-progress-circular
              indeterminate
              size="32"
              class="mt-2"
            ></v-progress-circular>
          </div>
        </div>
        <v-overlay
          :absolute="true"
          :value="messageError"
        >
          <p class="white--text text-h3 text-center">ไม่สามารถดึงข้อมูลได้</p>          
          <p class="white--text text-h5 text-center">โปรดรอสักครู่ แล้วลองใหม่อีกครั้ง</p>                    
        </v-overlay>
        
        <div v-for="(dateGroup, dateGroupIndex) in $attrs.value" 
          :key="'dateGroup_' + dateGroupIndex"
          :ref="'dateGroup_' + dateGroupIndex"

        >
            <div class="text-center my-4">{{ dateGroup.time }}</div>
            <message-group 
                v-for="(messageGroup, messageGroupIndex) in dateGroup.messageGroups"                
                :key="'messageGroup_' + messageGroupIndex"
                :value="messageGroup"
                :conversation="conversation"
                :first-group="dateGroupIndex == $attrs.value.length - 1"
            />
            <!-- 
              TODO

              Add Facebook Template Support

            -->
        </div>
        <div id="anchor"></div>
        <v-btn
          v-show="!isScrolledBottom"
          dark
          absolute          
          rounded
          style="bottom: 80px;left: 50%;transform: translateX(-50%);opacity: 0.6;z-index: 99"
          @click.stop="scrollToBottom"
        >
          <v-icon>{{ icons.mdiChevronDown }}</v-icon>
        </v-btn>
    </div>
</template>
<script>
import { mdiChevronDown } from '@mdi/js'
import MessageGroup from './MessageGroup.vue'
export default {
  props: {
    messageLoading: {
      default: false,
    },
    messageError: {
      default: false,
    },
    conversation: {
      default: null,
    },
  },
  /*
  watch: {
    '$attrs.value': function () {
      this.scrollToOldTop()
    },
  },
  */
  data() {
    return {
      isScrolledBottom: false,
      icons: {
        mdiChevronDown,
      },
      oldTop: null,
    }
  },
  methods: {
    scrollToBottom() {
      /*
      const element = this.$el

      element.scrollTop = element.scrollHeight
      */

      const anc = document.querySelector('#anchor')

      if (!anc) {
        const element = this.$el

        element.scrollTop = element.scrollHeight

        return
      }

      anc.scrollIntoView()
    },
    scrollBeforeTopSmooth() {
      this.$el.scrollTo({ left: 0, top: 200, behavior: 'smooth' })
    },
    /*
    scrollToOldTop() {
      if (!this.oldTop) return

      this.oldTop.scrollIntoView()
    },
    */
    onScroll(event) {
      const el = this.$el

      this.isScrolledBottom = false

      if (el && el.scrollTop + el.clientHeight >= el.scrollHeight - 100) {
        // Check if hit bottom
        this.isScrolledBottom = true
      }

      if (el && el.scrollTop <= 50 && !this.messageLoading) {
        // Check if hit top
        this.oldTop = this.$refs.dateGroup_0[0]
        this.$emit('loadNextPage')
      }

      if (el && el.scrollTop <= 50 && this.messageLoading) {
        this.scrollBeforeTopSmooth()
      }
    },
  },
  components: {
    MessageGroup,
  },
}
</script>
<style scoped>
.message-container-wrapper {
  overflow-y: scroll;
  height: 100%;
}

.message-container-wrapper * {
  overflow-anchor: none;
}

#anchor {
  overflow-anchor: auto;
  height: 1px;
}
</style>