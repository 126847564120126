<template>    
    <v-card class="" style="max-height: 90vh; height: 100%;">
      <chat-main></chat-main>
    </v-card>    
</template>
<script>
import { asyncGet, asyncPost } from '@/helpers/asyncAxios'
import useAppConfig from '@core/@app-config/useAppConfig'
import ChatMain from './components/ChatMain.vue'
export default {
  data() {
    return {
      icons: {},
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
  components: {
    ChatMain,
  },
}
</script>