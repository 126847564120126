<template>
    <div class="mt-2 d-flex flex-wrap"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-img
      :src="stickerURL"      
      max-height="100"
      max-width="100"            
    />      
  </div>
</template>
<script>
export default {
  props: ['stickerId', 'packageId'],
  computed: {
    stickerURL() {
      return 'https://stickershop.line-scdn.net/stickershop/v1/sticker/' + this.stickerId + '/android/sticker.png'
    },
  },
}
</script>