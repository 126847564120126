<template>
    <div class="d-flex h-full">
        <div class="ma-auto text-center" v-if="orders.length == 0">
          <div>
            <v-icon size="200">{{ icons.mdiShoppingOutline }}</v-icon>
          </div>          
          <div>
            <v-btn large class="primary" @click="$emit('callOrderCreator')"><v-icon>{{ icons.mdiPlus }}</v-icon>สร้างรายการขาย</v-btn>
          </div>          
        </div>
        <div v-else class="d-flex flex-column flex-grow-1">
          <div style="overflow-y: auto;">
            <div
              v-for="(order, index) in orders"
              :key="'order_' + order.id"
              @click="editOrder(index)"
              class="order-card"
            >
              <div class="px-6 py-4">
                <div class="d-flex align-center">
                  <div style="position: relative">
                    <channel-logo 
                        :value="{
                          name: order.salechannel.name,
                          channel_type: order.salechannel.channel_type,
                          profile: order.salechannel.profile
                        }"
                        class="white--text"
                        size="30"
                    ></channel-logo>
                    <SNSAvartar                        
                      :value="order.salechannel.channel_type"
                      class="floating-avatar"
                      size="15"
                    ></SNSAvartar>
                  </div>
                  <div class="ml-4 black--text">{{ order.orderNumber }}</div>
                </div>
                <div class="black--text mt-2">
                  อัพเดธล่าสุด {{ order.updatedAt }}
                </div>
                <div class="mt-2">
                  <v-chip :color="helpers.convertOrderStatusToColor(order.status)">{{ helpers.convertOrderStatusToText(order.status) }}</v-chip>              
                  <v-chip color="success" class="ml-2" v-if="order.isPaid">ชำระแล้ว</v-chip>                
                  <v-chip color="black" class="ml-2" v-else>ยังไม่ชำระ</v-chip>                
                </div>
              </div>            
              <v-divider></v-divider>
            </div>
          </div>
          <div
            style="max-height: 50px;"
            class="mt-auto"
          >
            <v-btn                         
            color="primary" 
            block 
            @click="$emit('callOrderCreator')"><v-icon>{{ icons.mdiPlus }}</v-icon>สร้างรายการขาย</v-btn>
          </div>          
        </div>
    </div>
</template>
<script>
import ChannelLogo from '@/components/ChannelLogo.vue'
import SNSAvartar from '@/components/SNSAvartar.vue'
import { convertOrderStatusToColor, convertOrderStatusToText } from '@/helpers/converter'
import { mdiPlus, mdiShoppingOutline } from '@mdi/js'
export default {
  props: ['orders'],
  data() {
    return {
      icons: {
        mdiShoppingOutline,
        mdiPlus,
      },
      helpers: {
        convertOrderStatusToText,
        convertOrderStatusToColor,
      },
    }
  },
  methods: {
    editOrder(orderIndex) {
      this.$emit('editOrder', orderIndex)
    },
  },
  components: {
    ChannelLogo,
    SNSAvartar,
  },
}
</script>
<style scoped>
.order-card {
  cursor: pointer;
}
</style>