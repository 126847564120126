<template>
    <div class="chat-image-slider mt-2 d-flex flex-wrap"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-img
        :src="originalContentUrl"
        :lazy-src="previewImageUrl"
        max-height="100"
        max-width="100"      
        @click="showLightBox()"
        v-if="!!previewImageUrl"
      />
        <v-progress-circular
        v-if="isLoading"
        indeterminate
        color="aoo"
        ></v-progress-circular>    
      <LightBox 
        :media="[
          {
            thumb: previewImageUrl,
            src: originalContentUrl
          }
        ]"        
        :showLightBox="false"
        siteLoading="ไม่พบรูปภาพ"
        ref="lightbox"
      ></LightBox>
    </div>
  </template>
  <script>
import { asyncGet } from '@/helpers/asyncAxios'
import LightBox from 'vue-image-lightbox'

export default {
  computed: {},
  data() {
    return {
      isLoading: false,
      originalContentUrl: null,
      previewImageUrl: null,
    }
  },
  created() {
    this.loadImage()
  },
  methods: {
    showLightBox() {
      this.$refs.lightbox.showImage(0)
    },
    async loadImage() {
      this.isLoading = true

      try {
        const { originalContentUrl, previewImageUrl } = await asyncGet('/chat/message/' + this.$attrs.value)
        this.originalContentUrl = originalContentUrl
        this.previewImageUrl = previewImageUrl
      } catch (error) {
        console.log(error)
      }

      this.isLoading = false
    },
  },
  components: {
    LightBox,
  },
}
</script>
  <style scoped>
.chat-image-slider {
  max-width: 80%;
  flex-wrap: 1;
}
</style>