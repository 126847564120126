<template>
    <div class="py-2 px-4 base-text base-color elevation-1">
        <h3 class="">มีการพูดถึงคุณใน Story</h3>
        
        <div class="d-flex flex-column mt-2">
          <v-btn @click="showStory" v-if="!$attrs.value.isStoryExpired && !isDeleted">ดู Story</v-btn>
          <v-btn v-else disabled>หมดอายุ</v-btn>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      isDeleted: false,
    }
  },
  mounted() {
    if (this.$attrs.value.isStoryExpired) return

    /*
    fetch(this.$attrs.value.storyURL, { method: 'HEAD' })
      .then(response => {
        if (response.status === 404) {
          this.isDeleted = true
        } else {
          this.isDeleted = false
        }
      })
      .catch(error => {
        this.isDeleted = true
        //console.error('An error occurred:', error)
      })

    */
  },
  methods: {
    showStory() {
      window.open(this.$attrs.value.storyURL)
    },
  },
}
</script>
<style scoped>
.base-text {
  word-break: break-all;
  white-space: pre-line;
  max-width: 100%;
  box-shadow: 0 1px 3px 0 rgba(94, 86, 105, 0.2), 0 2px 1px -1px rgba(94, 86, 105, 0.12),
    0 1px 1px 0 rgba(94, 86, 105, 0.14);
}

.base-color {
  background: #fff;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
</style>