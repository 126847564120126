<template>
    <div>
      <div v-show="files.length > 0" style="max-height: 300px; overflow: auto;"> 
        <VueFileAgent
          :uploadUrl="url + '/upload'" 
          :uploadHeaders="{ 
            Authorization: 'Bearer ' + $store.getters['auth/GET_ACCESS_TOKEN'],
            Company: $route.params.company_id
          }"
          :maxSize="'10MB'"
          v-model="files"                 
          :multiple="true"
          deletable
          :meta="false"
          helpText="เลือกไฟล์ หรือ ลากไฟล์มาวางที่นี่"
          :accept="'.jpg,.jpeg,.png,.mp4,.avi'"
          ref="vueFileAgent"
        ></VueFileAgent>
        <!--
        <VueFileAgent
            :uploadUrl="url + '/chat/conversations/' + conversation.id + '/uploadMediaAttachment'" 
            :uploadHeaders="{ 
              Authorization: 'Bearer ' + $store.getters['auth/GET_ACCESS_TOKEN'],
              Company: $route.params.company_id
            }"
            :maxSize="'1MB'"
            v-model="files"                 
            :multiple="true"
            deletable
            :meta="false"
            helpText="เลือกไฟล์ หรือ ลากไฟล์มาวางที่นี่"
            accept="image/*"
            ref="vueFileAgent"
          ></VueFileAgent>
          -->
      </div>
      <div class="reply-container d-flex align-center px-2">
          <v-btn 
              icon
              @click="showSavedResponseDialog = true"
          >
              <v-icon>{{ icons.mdiMessageProcessingOutline }}</v-icon>
          </v-btn>
          <v-btn 
              icon
              @click="uploadFile"
          >
              <v-icon>{{ icons.mdiPaperclip }}</v-icon>
          </v-btn>
          <v-textarea
              outlined
              dense            
              :append-icon="icons.mdiEmoticonHappyOutline"
              hide-details
              v-model="text"
              @click:append="showEmojiPicker = !showEmojiPicker"
              @keydown.enter.exact.prevent="sendMessage"
              class="reply-box"
              rows="1"
              auto-grow
          >            
          </v-textarea>
          <picker v-if="showEmojiPicker"
          :data="emojiIndex"
          set="facebook"
          :showPreview="false"
          @select="addEmoji"
          style="position:absolute; bottom: 70px; width: 100%" />
      </div>

      <v-dialog        
        v-model="showSavedResponseDialog"
        max-width="500"
        max-height="500"
        class="mx-auto"
      >
        <saved-response-dialog
          @close="showSavedResponseDialog = false"
          @sendResponse="$emit('sendResponse', $event)"
          @setResponse="setResponse"          
        ></saved-response-dialog>
      </v-dialog>
    </div>
</template>
<script>
import { convertFromServerToVueFileAgent, convertFromVueFileAgentToServer } from '@/helpers/converter'
import { mdiEmoticonHappyOutline, mdiMessageProcessingOutline, mdiPaperclip } from '@mdi/js'
import { EmojiIndex, Picker } from 'emoji-mart-vue-fast'
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import data from 'emoji-mart-vue-fast/data/facebook.json'
import SavedResponseDialog from './messages/SavedResponseDialog.vue'
let emojiIndex = new EmojiIndex(data)

export default {
  props: {
    conversation: {
      default: null,
    },
  },
  data() {
    return {
      originalImages: [],
      showSavedResponseDialog: false,
      text: '',
      files: [],
      saved_id: null,
      url: process.env.VUE_APP_ROOT_API,
      emojiIndex: emojiIndex,
      showEmojiPicker: false,
      icons: {
        mdiMessageProcessingOutline,
        mdiPaperclip,
        mdiEmoticonHappyOutline,
      },
    }
  },
  methods: {
    addEmoji(event) {
      this.text += event.native
    },
    sendMessage() {
      /*
      const formattedImages = this.images.map(image => {
        if (!!image.upload.data) return image

        const found = originalImages.find(item => item.URI == image.urlResized)

        if (!found) return

        return {
          ...image,
          upload: {
            data: found,
          },
        }
      })

      const message = {
        text: this.text,
        files: this.files.map(item => {
          let type = ''

          if (item.type.startsWith('video/')) type = 'video'
          else if (item.type.startsWith('image/')) type = 'image'

          return {
            id: item.upload.data,
            type: type,
          }
        }),
      }
      */
      const originalImages = this.originalImages

      const formattedImages = this.files.map(image => {
        if (!!image.upload.data) return image

        const found = originalImages.find(item => item.URI == image.urlResized)

        if (!found) return

        return {
          ...image,
          upload: {
            data: found,
          },
        }
      })

      const message = {
        text: this.text,
        files: convertFromVueFileAgentToServer(formattedImages),
        saved_id: this.saved_id,
      }

      this.text = ''
      this.files = []
      this.saved_id = null

      this.$emit('sendMessage', message)
    },
    uploadFile() {
      this.$refs.vueFileAgent.$refs.fileInput.click()
    },
    setResponse({ message, images, id }) {
      this.originalImages = images

      this.saved_id = id
      this.text = message
      this.files = convertFromServerToVueFileAgent(images)
    },
  },
  components: {
    Picker,
    SavedResponseDialog,
  },
}
</script>
<style scoped>
.reply-container {
  padding-top: 6px;
  padding-bottom: 6px;
  border-top: 1px solid #a4a4a4;
}

.reply-box textarea {
  resize: none;
  overflow: hidden;
  height: 100%;
}
</style>