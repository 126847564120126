import { timestampToLongText } from "@/helpers/dateTimeConverter";
import { MessageGroup } from "./MessageGroup";

export class DateGroup
{
    #messageGroups
    #timestamp    

    constructor(timestamp) {        
        this.#timestamp = timestamp
        this.#messageGroups = []
    }

    get time() {
        return timestampToLongText(this.#timestamp)        
    }

    get messageGroups() {
        return this.#messageGroups
    }

    get timestamp() {
        return this.#timestamp
    }

    set timestamp(value) {
        this.#timestamp = value
    }

    isSameGroup(timestamp) {       
        if (Math.abs(timestamp - this.#timestamp) >= 10800000) return false        

        // if (this.#timestamp - timestamp >= 10800000) return false

        return true
    }

    _getTopMessageGroup() {
        if (this.#messageGroups.length == 0) return null

        return this.#messageGroups[0]
    }

    _getLatestMessageGroup() {
        return this.#messageGroups[this.messageGroups.length - 1]
    }

    #getMessageGroup(rawMessage) {
        // Will create new message group in the following conditions
        // 1. diff sender (also between diff admins)
        // 2. timestamp diff more than 600000 ms
        // 3. message is an attachment
        // 4. message is a reply message

        /*
        let messageGroup = this.messageGroups.find(item => item.isSameGroup(rawMessage))

        if (!messageGroup) {
            messageGroup = new MessageGroup(rawMessage)
            this.#messageGroups.push(messageGroup)
        }

        return messageGroup
        */

        let latestGroup = this._getTopMessageGroup()

        if (!latestGroup || !latestGroup.isSameGroup(rawMessage)) {
            latestGroup = new MessageGroup(rawMessage)
            this.#messageGroups.unshift(latestGroup)
        } 

        return latestGroup
    }

    addMessage(rawMessage) {
        const { timestamp } = rawMessage

        if (!this.isSameGroup(timestamp)) return;

        if (this.#timestamp > timestamp)
            this.#timestamp = timestamp

        const messageGroup = this.#getMessageGroup(rawMessage)

        messageGroup.addMessage(rawMessage)        
    }

    pushMessage(rawMessage) {                
        let latestGroup = this._getLatestMessageGroup()

        if (!latestGroup || !latestGroup.isSameGroup(rawMessage)) {
            latestGroup = new MessageGroup(rawMessage)
            this.#messageGroups.push(latestGroup)
        }

        return latestGroup.pushMessage(rawMessage)
    }
}