import { Message } from "../Message";

export class LineMessage extends Message
{
    #_id
    #type
    #stickerId
    
    #template

    #originalContentUrl
    #previewImageUrl

    constructor(_id, message, timestamp) {             
        super({...message, mid: message.id}, timestamp)           

        const { type, stickerId, packageId, originalContentUrl, previewImageUrl, template } = message

        this.#_id = _id
        this.#type = type
        this.#stickerId = stickerId

        this.#template = template

        if (type == 'image' || type == 'video') {
            this.#originalContentUrl = originalContentUrl
            this.#previewImageUrl = previewImageUrl
        }
    }

    get _id() {
        return this.#_id
    }

    get type() {                
        return this.#type
    }

    get template() {
        return this.#template
    }

    get stickerId() {
        return this.#stickerId
    }    

    get contentURL() {
        return this.#originalContentUrl
    }

    get previewURL() {
        return this.#previewImageUrl
    }

    get text() {
        return this.type == 'template' ? this.template.text : super.text
    }

    get video() {
        return this.contentURL
    }

    markSent(_id, message, timestamp) {        
        super.markSent({...message, mid: message.id}, timestamp)

        const { type, stickerId, packageId, originalContentUrl, previewImageUrl } = message

        this.#_id = _id
        this.#type = type
        this.#stickerId = stickerId

        if (type == 'image') {
            this.#originalContentUrl = originalContentUrl
            this.#previewImageUrl = previewImageUrl
        }
    }
}