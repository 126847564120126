<template>
    <v-avatar     
    v-bind="$attrs"
    >
      <img :src="channelTypeAvatar">      
    </v-avatar>    
</template>
<script>
import instagramSVG from '@/assets/images/3rdparty/instagram.svg'
import FBSmall from '@/assets/images/fb_small.png'
import LINESmall from '@/assets/images/line_small.png'
export default {
  computed: {
    channelTypeAvatar() {
      if (!this.$attrs.value) return null

      if (this.$attrs.value == 'sc_fb') return FBSmall

      if (this.$attrs.value == 'sc_ig') return instagramSVG

      if (this.$attrs.value == 'line') return LINESmall
    },
  },
}
</script>