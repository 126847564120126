<template>
    <div class="d-flex h-full chat-content-area">
        <div class="d-flex flex-column flex-grow-1 chat-component-container" style="background-color: white; min-height: 97px" v-if="$vuetify.breakpoint.mdAndUp">
            <chat-filter v-model="filters"></chat-filter>
            <v-divider></v-divider>
            <chat-list 
            class="flex-grow-1 flex-shrink-1" 
            :filters="filters" 
            v-model="conversation"
            ref="chatList"            
            ></chat-list>
        </div>
        <v-navigation-drawer
          v-model="isLeftSidebarOpen"
          width="374"
          touchless          
          mobile-breakpoint="sm"
          :temporary="$vuetify.breakpoint.xsOnly"
          absolute
          v-else
        >
          <chat-filter v-model="filters" :closeable="true" @close="isLeftSidebarOpen = false"></chat-filter>
            <v-divider></v-divider>
            <chat-list 
            class="flex-grow-1 flex-shrink-1" 
            :filters="filters" 
            v-model="conversation"
            ref="chatList_mobile"            
          ></chat-list>
        </v-navigation-drawer>
        <v-divider vertical></v-divider>
        <div class="flex-grow-1 flex-shrink-0 chat-component-container" :style="{'max-width': $vuetify.breakpoint.mdAndUp ? '33.33%' : '100%'}">
          <chat-message 
          v-if="conversation"
          :value="conversation" 
          ref="message" 
          :tags="!!customer ? customer.tags : []"
          @addTag="addTag"
          @removeTag="removeTag"
          @openSidebar="isLeftSidebarOpen = true"
          @refreshMetadata="getConversationMetadata(conversation.id)"
          @openChatSale="isRightSidebarOpen = true"
          ></chat-message>
          <div
            v-else
            class="d-flex align-center justify-center flex-grow-1 flex-column h-full"
          >
            <v-avatar
              size="109"
              class="elevation-3 mb-6 bg-card"
            >
              <v-icon
                size="50"
                class="rounded-0 text--primary"
              >
                {{ icons.mdiMessageTextOutline }}
              </v-icon>
            </v-avatar>
            <p
              class="mb-0 px-6 py-1 font-weight-medium text-lg elevation-3 rounded-xl text--primary bg-card"
              :class="[{ 'cursor-pointer': $vuetify.breakpoint.smAndDown }]"
              @click="startConversation"
            >
              เริ่มการสนทนา
            </p>
          </div>
        </div>
        <v-divider vertical></v-divider>
        <chat-sale 
        v-if="$vuetify.breakpoint.mdAndUp && !!conversation" 
        :value="conversation"  
        :orders="orders"
        :customer="customer"
        class="flex-grow-1 chat-component-container"         
        :loading="saleLoading"
        @refreshMetadata="getConversationMetadata(conversation.id)"
        @removeTag="removeTag"
        ></chat-sale>
        <v-navigation-drawer
          v-else-if="!$vuetify.breakpoint.mdAndUp && !!conversation"
          v-model="isRightSidebarOpen"
          width="374"
          absolute
          temporary
          touchless
          :right="false"
        >
          <chat-sale                     
          :value="conversation"  
          :orders="orders"
          :customer="customer"
          class="flex-grow-1 h-full"
          :loading="saleLoading"
          @refreshMetadata="getConversationMetadata(conversation.id)"
          @removeTag="removeTag"
          @closeChatSale="isRightSidebarOpen = false"
          ></chat-sale>
        </v-navigation-drawer>
    </div>
</template>
<script>
import { Order } from '@/classes/order/Order'
import { asyncGet, asyncPost } from '@/helpers/asyncAxios'
import { mdiMessageTextOutline } from '@mdi/js'
import ChatFilter from './ChatFilter.vue'
import ChatList from './ChatList.vue'
import ChatMessage from './ChatMessage.vue'
import ChatSale from './ChatSale.vue'
export default {
  data() {
    return {
      filters: {
        textSearch: null,
        channels: null,
        tags: null,
        behaviors: null,
      },
      conversation: null,
      orders: [],
      customer: null,
      saleLoading: false,
      abortController: null,
      isMobile: false,
      icons: {
        mdiMessageTextOutline,
      },
      isLeftSidebarOpen: false,
      isRightSidebarOpen: false,
    }
  },
  watch: {
    '$store.state.socket.chat.latestEvent': function (rawEvent) {
      if (!rawEvent) return

      const { conversation, message, channelInfo } = rawEvent

      const { _id } = conversation

      const selectedConversation = this.conversation

      if (!!message.message.is_deleted && selectedConversation && selectedConversation.id == _id) {
        selectedConversation.unsent(message, conversation.snippet)

        if (this.$vuetify.breakpoint.mdAndUp) this.$refs.chatList.refreshConversation()
        else this.$refs.chatList_mobile.refreshConversation()

        this.$refs.message.refreshMessage()
      } else {
        if (this.$vuetify.breakpoint.mdAndUp) this.$refs.chatList.touchConversation(conversation, channelInfo)
        else this.$refs.chatList_mobile.touchConversation(conversation, channelInfo)

        if (selectedConversation && selectedConversation.id == _id) {
          selectedConversation.pushMessage(message)

          this.$store.commit('socket/READ_CHAT', selectedConversation.unreadCount)
          selectedConversation.read()
          this.$refs.message.refreshMessage()

          asyncPost('/chat/conversations/' + _id + '/mark_read', [])
        }
      }

      this.$store.commit('socket/CLEAR_LATEST_CHAT_EVENT')
    },
    conversation(value) {
      this.customer = null
      this.orders = []

      // if (value) this.getConversationMetadata(value.id)
    },
    '$vuetify.breakpoint.mdAndUp': function (value) {
      this.isMobile = value
    },
  },
  created() {
    // console.log(this.$vuetify.breakpoint.mdAndUp)
    this.isMobile = this.$vuetify.breakpoint.mdAndUp
  },
  methods: {
    /*
    makeid(length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      let counter = 0
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
        counter += 1
      }
      return result
    },
    addMessage() {
      const conversation = this.$attrs.value

      const tmpMessage = {
        sender: {
          id: '2452457848154571',
        },
        recipient: {
          id: '1508264892638485',
        },
        timestamp: Date.now(),
        message: {
          mid: this.makeid(20),
          text: 'ทดสอบข้อความใหม่',
        },
        conversation_id: '61352ad8416ed02e7fe1a1b7',
      }

      conversation.addMessage(tmpMessage)
      this.dateGroups = [...conversation.dateGroups]

      this.$nextTick(() => {
        this.$nextTick(() => {
          const el = this.$refs.messageContainer.$el

          if (!el) return

          el.scrollTo({ left: 0, top: el.scrollHeight + 50, behavior: 'smooth' })
        })
      })
    },
    */
    startConversation() {
      if (this.$vuetify.breakpoint.mdAndUp) return

      this.isLeftSidebarOpen = true
    },
    async getConversationMetadata(conversationId) {
      this.saleLoading = true

      this.customer = null
      this.orders = []

      if (this.abortController) {
        this.abortController.abort()
      }

      this.abortController = new AbortController()

      try {
        const { customer, orders } = await asyncGet(
          '/chat/conversations/' + conversationId + '/metadata',
          {},
          {
            signal: this.abortController.signal,
          },
        )

        this.orders = orders.map(item => {
          const order = new Order(item)
          /*
          const { products } = item

          products.forEach(element => {
            const orderItem = new OrderItem(
              element.id,
              element.images && !!element.images[0] ? element.images[0].URI : null,
              element.name,
              element.sku,
              element.barCode,
              element.pivot.note,
              element.pivot.quantity,
              element.pivot.price,
              element.pivot.discount,
              element.pivot.discount_type,
              element.pivot.vat,
            )

            order.addOrderItem(orderItem)
          })
            */

          return order
        })

        this.customer = customer
      } catch (error) {
        console.log(error)
      } finally {
        this.abortController = null
      }

      this.saleLoading = false
    },
    async addTag(tagName) {
      try {
        const response = await asyncPost('/chat/conversations/' + this.conversation.id + '/addTag', {
          tagName: tagName,
        })

        this.customer = response
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    async removeTag(tagName) {
      try {
        const response = await asyncPost('/chat/conversations/' + this.conversation.id + '/removeTag', {
          tagName: tagName,
        })

        this.customer = response
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
  },
  components: {
    ChatFilter,
    ChatList,
    ChatMessage,
    ChatSale,
  },
}
</script>
<style scoped>
.chat-component-container {
  max-width: 33.33%;
  background-color: rgba(94, 86, 105, 0.04);
}
</style>