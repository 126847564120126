<template>
    <v-card>
        <v-card-title>
            ข้อความตอบกลับ

            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                @click="callCreateDialog"
            >เพิ่มใหม่</v-btn>
        </v-card-title>

        <v-card-text style="">
            <div class="d-flex">
                <v-text-field
                outlined
                v-model="search"
                placeholder="ค้นหา ..."
                dense
                ></v-text-field>

                <v-select
                outlined
                dense
                :items="items"
                item-text="text"
                item-value="value"
                v-model="orderBy"
                class="ml-2"
                style="max-width: 150px;"
                ></v-select>
            </div>
            <div style="overflow-y: auto;min-height: 300px;max-height: 500px;" v-if="!loading">
                <div 
                v-for="(response, index) in responses"
                class="d-flex flex-row savedresponse-item"
                @click.stop="setResponse(response)"
                >
                    <div style="width: 50px;">
                        <!-- FIRST IMAGE -->
                        <v-img 
                        v-if="response.images && response.images[0]"
                        :src="response.images[0].URI"
                        max-height="50"
                        max-width="50"
                        ></v-img>
                        <v-icon v-else size="50">{{ icons.mdiImageOutline }}</v-icon>
                    </div>
                    <div class="d-flex flex-column ml-2">
                        <div class="black--text text-body-2">
                            <!-- NAME -->
                            {{ response.name }}
                        </div>
                        <div>
                            {{ response.message }}
                            <!-- MESSAGE -->
                        </div>
                    </div>
                    <div class="ml-auto">
                        <!-- ACTION -->
                        <v-btn 
                        icon 
                        @click.stop="callEditDialog(response)">
                            <v-icon>{{ icons.mdiPlaylistEdit }}</v-icon>
                        </v-btn>
                        <v-btn 
                        icon 
                        @click.stop="deleteSingle(response.id, response.name)">
                            <v-icon>{{ icons.mdiDelete }}</v-icon>
                        </v-btn>
                    </div>                    
                </div>                
            </div>
            <div v-else style="height: 300px;" class="d-flex">
                <v-progress-circular
                indeterminate
                color="primary"
                class="ma-auto"
                size="100"
                ></v-progress-circular>
            </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
            <v-btn
            color="secondary"               
            outlined                 
            large
            :disabled="sendingData"
            @click="$emit('close')"
            >
            ยกเลิก
            </v-btn>            
        </v-card-actions>

        <v-dialog
            v-model="createDialog"
            class="ma-auto" max-width="500" min-height="500"
        >
            <v-form
                v-model="isFormValid"
            >
                <v-card>
                    <v-card-title>{{ mode == 'create' ? 'เพิ่ม' : 'แก้ไข' }}ข้อความตอบกลับ</v-card-title>

                    <v-card-text>
                        <v-text-field
                        outlined
                        v-model="formData.name"
                        label="ชื่อ *"
                        dense
                        :rules="[validators.required]"
                        ></v-text-field>

                        <v-textarea
                        outlined
                        v-model="formData.message"
                        label="ข้อความ *"
                        dense
                        :rules="[validators.required]"
                        ></v-textarea>

                        <VueFileAgent 
                            :uploadUrl="url + '/upload'" 
                            :uploadHeaders="{ 
                            Authorization: 'Bearer ' + $store.getters['auth/GET_ACCESS_TOKEN']
                            }"
                            :maxSize="'10MB'"
                            v-model="formData.image_id"     
                            :sortable="'handle'"       
                            :multiple="true"
                            deletable
                            :meta="false"
                            :accept="'.jpg,.jpeg,.png,.mp4,.avi'"
                            helpText="เลือกไฟล์ หรือ ลากไฟล์มาวางที่นี่"
                        ></VueFileAgent>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>                    
                        <v-btn
                        color="primary"               
                        large          
                        :disabled="!isFormValid"
                        :loading="sendingData"
                        @click="submitForm"
                        >
                        {{ mode == 'create' ? "สร้าง" : "แก้ไข"}}
                        </v-btn>
                        <v-btn
                        color="secondary"               
                        outlined                 
                        large
                        :disabled="sendingData"
                        @click="createDialog = false"
                        >
                        ยกเลิก
                        </v-btn>                    
                    </v-card-actions>
                </v-card>
            </v-form>            
        </v-dialog>
    </v-card>
</template>
<script>
import { asyncDelete, asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import { convertFromServerToVueFileAgent, convertFromVueFileAgentToServer } from '@/helpers/converter'
import { required } from '@core/utils/validation'
import { mdiDelete, mdiImageOutline, mdiPlaylistEdit } from '@mdi/js'
export default {
  data() {
    return {
      url: process.env.VUE_APP_ROOT_API,
      sendingData: false,
      loading: false,
      abortController: null,
      isFormValid: false,
      createDialog: false,
      formData: {
        name: null,
        message: null,
        image_id: [],
        originalImages: null,
      },
      searchTimer: false,
      mode: 'create',
      search: null,
      orderBy: 'freq',
      items: [
        {
          text: 'ที่ใช้บ่อย',
          value: 'freq',
        },
        {
          text: 'ที่ใช้ล่าสุด',
          value: 'latest',
        },
      ],
      responses: [],
      validators: {
        required,
      },
      icons: {
        mdiPlaylistEdit,
        mdiDelete,
        mdiImageOutline,
      },
    }
  },
  watch: {
    search(value) {
      if (this.searchTimer) clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(this.getSavedResponseList, 250, value, this.orderBy)
    },
    orderBy(value) {
      if (this.searchTimer) clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(this.getSavedResponseList, 250, this.search, value)
    },
  },
  created() {
    this.getSavedResponseList(null, this.orderBy)
  },
  methods: {
    async getSavedResponseList(search, orderBy) {
      this.loading = true

      if (this.abortController) this.abortController.abort()

      this.abortController = new AbortController()

      try {
        const data = await asyncGet(
          '/chat/savedResponse',
          {
            search: search,
            orderBy: orderBy,
          },
          {
            signal: this.abortController.signal,
          },
        )

        this.responses = data
      } catch (error) {
        console.log(error)
      }

      this.loading = false
    },
    async submitForm() {
      this.sendingData = true

      try {
        if (this.mode == 'create') {
          await asyncPostAsJson('/chat/savedResponse', {
            ...this.formData,
            image_id: convertFromVueFileAgentToServer(this.formData.image_id),
          })
        } else {
          /*
          START OF HOTFIX SECTION

          Because VueFileAgent limitation when upload addition images for products, we need code in this section for preventing data loss
          */
          const originalImages = this.formData.originalImages

          const formattedImages = this.formData.image_id.map(image => {
            if (!!image.upload.data) return image

            const found = originalImages.find(item => item.URI == image.urlResized)

            if (!found) return

            return {
              ...image,
              upload: {
                data: found,
              },
            }
          })
          /*
          END OF HOTFIX SECTION
          */
          await asyncPostAsJson('/chat/savedResponse/' + this.formData.id, {
            ...this.formData,
            _method: 'PUT',
            image_id: convertFromVueFileAgentToServer(formattedImages),
          })
        }
      } catch (error) {
        console.log(error)
      }

      this.sendingData = false

      this.createDialog = false

      this.getSavedResponseList(this.search, this.orderBy)
    },
    callCreateDialog() {
      this.createDialog = true
      this.mode = 'create'
      this.formData = {
        name: null,
        message: null,
        image_id: [],
      }
    },
    callEditDialog({ id, name, message, images }) {
      this.createDialog = true
      this.mode = 'edit'

      this.formData = {
        id: id,
        name: name,
        message: message,
        image_id: convertFromServerToVueFileAgent(images),
        originalImages: images,
      }
    },
    setResponse(response) {
      this.$emit('setResponse', response)
    },
    async deleteSingle(id, name) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body: 'คุณต้องการที่จะลบ "' + name + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/chat/savedResponse/' + id)

        this.getSavedResponseList(this.search, this.orderBy)
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    async sendResponse(id) {
      this.$emit('sendResponse', id)
    },
  },
}
</script>
<style scoped>
.savedresponse-item:hover {
  background-color: #ee2f2e;
  transition: all 0.5s;
  cursor: pointer;
  color: white !important;
}

.savedresponse-item:hover .black--text,
.savedresponse-item:hover .v-icon {
  color: white !important;
}
</style>