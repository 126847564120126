<template>
    <div class="d-flex flex-wrap align-center">
        <v-chip-group            
            column
          >
            <v-chip
                v-for="(tag, index) in showTags"
                :key="'tag_' + index"
                close
                :close-icon="icons.mdiClose"
                class=""
                @click:close="removeTag(index)"
            >{{ tag.name }}</v-chip>
        </v-chip-group>        
        <v-btn
            v-if="isAddable"
            fab
            x-small            
            style="border-style: dotted;"
            @click="isShowTagDialog = true"
        ><v-icon>{{ icons.mdiPlus }}</v-icon></v-btn>
        <v-dialog
            v-model="isShowTagDialog"
        >
            <v-card max-width="360" class="ma-auto">
                <v-card-title>
                    ติด Tag
                </v-card-title>

                <v-card-text>
                    <v-text-field
                    @keydown.enter="addTag"
                    outlined
                    hide-selected                            
                    v-model.trim="tag"
                    label="ชื่อ tag"
                    dense
                    ></v-text-field>
                </v-card-text>    
                
                <v-card-actions>
                    <v-btn
                    color="secondary"               
                    outlined                 
                    large
                    :disabled="sendingData"
                    @click="isShowTagDialog = false"
                    >
                    ยกเลิก
                    </v-btn>
                    <v-btn
                    color="primary"               
                    large          
                    :disabled="!tag"
                    :loading="sendingData"
                    @click="addTag"
                    class="ml-auto"
                    >
                    ติด
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import DelayCombobox from '@/components/DelayCombobox.vue'
import { mdiClose, mdiPlus } from '@mdi/js'
export default {
  props: ['isAddable'],
  components: { DelayCombobox },
  data() {
    return {
      isShowTagDialog: false,
      sendingData: false,
      tag: null,
      showTags: null,
      icons: {
        mdiPlus,
        mdiClose,
      },
    }
  },
  watch: {
    '$attrs.value': function (value) {
      this.showTags = value
    },
  },
  created() {
    this.showTags = this.$attrs.value
  },
  methods: {
    async addTag() {
      const found = this.showTags.find(item => item.name == this.tag)

      if (found) {
        this.tag = null
        return
      }

      this.showTags.push({
        id: null,
        name: this.tag,
      })

      this.$emit('addTag', this.tag)
      this.tag = null
    },
    async removeTag(index) {
      const tag = this.showTags[index]
      this.$emit('removeTag', tag.name)

      this.showTags.splice(index, 1)
    },
  },
}
</script>