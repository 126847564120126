<template>
    <div>
        <v-text-field
        outlined
        dense
        v-model="searchInput"
        placeholder="ค้นหาสินค้า, บาร์โค้ด, sku"
        clearable
        ></v-text-field>
        <div class="d-flex" v-if="isLoading">
            <v-progress-circular
                class="mx-auto"
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>
        <div v-else style="height: 100%;">
            <v-list three-line style="max-height: 400px;overflow-y: scroll;">
                <v-list-item-group>
                  <v-list-item v-for="(product, index) in products" 
                  @click="$emit('addProduct', product)" 
                  :key="product.id"
                  class="d-flex align-start"
                  >
                    <div class="pt-4 flex-grow-1">
                      <div class="d-flex">
                        <light-box v-if="!!product.images && !!product.images[0]"
                        :src="product.images[0].URI"
                        max-width="70"
                        max-height="70"
                        ></light-box>
                        <product-image v-else :image="null" />

                        <div class="d-flex flex-column ml-2">
                            <div class="black--text">{{ product.name }}</div>
                            <div class="mt-auto text-caption black--text">{{ product.sku }}</div>
                        </div>
                      </div>                    

                      <div class="pa-2 d-flex text-body-2">
                          <div class="flex-grow-1">
                            <div v-if="product.discountedPrice">
                              <span class="error--text">{{ product.discountedPrice | formatNumber }} ฿</span>
                              <strike class="mute ml-2">{{ product.defaultPrice | formatNumber }} ฿</strike>
                            </div>                            
                            <div v-else>{{ product.defaultPrice | formatNumber }} ฿</div>
                          </div>                            
                          <div class="ml-auto flex-grow-1 text-right">พร้อมขาย : {{ getAvailable(product) }}</div>
                      </div>

                      <v-divider></v-divider>
                    </div>                    
                  </v-list-item>
                </v-list-item-group>
            </v-list>
        </div>
    </div>
</template>
<script>
import { asyncGet } from '@/helpers/asyncAxios'
export default {
  props: ['url'],
  data() {
    return {
      searchInput: null,
      searchTimer: null,
      abortController: new AbortController(),
      products: null,
      isLoading: false,
    }
  },
  watch: {
    searchInput() {
      if (this.searchTimer) clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(this.fetchData, 200, this.searchInput)
    },
  },
  created() {
    this.fetchData('')
  },
  methods: {
    getAvailable({ inventory }) {
      if (!inventory || inventory.length == 0) return 'ไม่จำกัด'

      const currentInventory = inventory[0]

      return currentInventory.quantity - currentInventory.reserved
    },
    async fetchData(input) {
      this.isLoading = true
      if (this.abortController) {
        this.abortController.abort()
      }

      this.abortController = new AbortController()

      try {
        const response = await asyncGet(
          this.url,
          {
            search: input,
          },
          {
            signal: this.abortController.signal,
          },
        )

        this.products = response
      } catch (error) {
        console.log(error)
      } finally {
        this.abortController = null
        this.isLoading = false
      }
    },
  },
}
</script>