<template>
    <div class="chat-list-container px-3 py-3" @scroll="onScroll">
        <v-list-item-group                    
          color="primary"
          transition="slide-x-transition"
        >
          <transition-group name="list">                                                
              <v-list-item 
              v-for="(conversation, index) in conversations"
              two-line 
              :key="'conversation_' + conversation.id" 
              class="chat-list-item rounded-lg"
              @click="selectConversation(conversation)"
              >
                  <v-list-item-avatar class="chat-avatar" style="">
                      <img :src="conversation.ownerProfile" @error="handleErrorImage">
                  </v-list-item-avatar>

                  <v-list-item-content>
                      <v-list-item-title><span>{{ conversation.ownerName }}</span><span v-if="conversation.unreadCount > 0" class="unread-dot ml-2"></span></v-list-item-title>
                      <v-list-item-subtitle class="mt-2">{{ textCut(conversation.snippet, 30) }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                      <div style="position: relative">
                        <channel-logo 
                            :value="{
                              name: conversation.channelName,
                              channel_type: conversation.type,
                              profile: conversation.profile
                            }"
                            class="white--text"
                            size="30"
                        ></channel-logo>
                        <SNSAvartar                        
                          :value="conversation.type"
                          class="floating-avatar"
                          size="15"
                        ></SNSAvartar>
                      </div>                    
                      <v-list-item-action-text v-text="conversation.time"></v-list-item-action-text>                
                  </v-list-item-action>
                  
              </v-list-item>                                    
          </transition-group>                  
        </v-list-item-group>
        <v-list-item two-line class="chat-list-item py-4" v-if="conversationLoading" style="border: unset">
          <v-list-item-content>
            <v-list-item-title class="text-center">
              กำลังโหลด...
            </v-list-item-title>
            <v-progress-circular
              indeterminate
              size="32"
              class="mt-2"
            ></v-progress-circular>
          </v-list-item-content>
        </v-list-item>
        <v-overlay
          :absolute="true"
          :value="conversationError"
        >
          <p class="white--text text-h3 text-center">ไม่สามารถดึงข้อมูลได้</p>          
          <p class="white--text text-h5 text-center">โปรดรอสักครู่ แล้วลองใหม่อีกครั้ง</p>                    
        </v-overlay>
    </div>
</template>
<script>
import DefaultProfileImage from '@/assets/images/avatars/1.png'
import { Conversation } from '@/classes/chat/Conversation'
import { LineConversation } from '@/classes/chat/line/LineConversation'
import ChannelLogo from '@/components/ChannelLogo.vue'
import SNSAvartar from '@/components/SNSAvartar.vue'
import { asyncGet, asyncPost } from '@/helpers/asyncAxios'
import axios from 'axios'
export default {
  props: ['filters'],
  data() {
    return {
      currentPage: 1,
      conversationLoading: false,
      conversationError: true,
      nextPagination: null,
      fetchTimer: null,
      conversations: [],
      DefaultProfileImage: DefaultProfileImage,
      tmpForTest: null,
      conversationAbortContoller: null,
    }
  },
  created() {
    this.conversationAbortContoller = new AbortController()
    this.getConversations({}, 1)
  },
  watch: {
    filters(value) {
      if (this.fetchTimer) clearTimeout(this.fetchTimer)

      this.fetchTimer = setTimeout(this.getConversations, 250, value, 1)
    },
  },
  methods: {
    touchConversation(conversation, salechannel) {
      const { _id, unread_count, ref_id, snippet, timestamp, owner } = conversation

      const find = this.conversations.find(item => item.id == _id)

      if (!!find) {
        find.touch(timestamp, snippet)
        this.conversations = [...this.conversations]
        this.moveConversationToTop(this.getListIndexByConversationId(_id))
      } else {
        let newConversation
        if (salechannel.channel_type == 'line') {
          newConversation = new LineConversation(_id, ref_id, timestamp, unread_count, snippet, salechannel, owner)
        } else {
          newConversation = new Conversation(_id, ref_id, timestamp, unread_count, snippet, salechannel, owner)
        }

        // TODO : channel_type ? or type

        this.conversations.unshift(newConversation)
      }
    },
    refreshConversation() {
      this.conversations = [...this.conversations]
    },
    moveConversationToTop(conversationIndex) {
      if (conversationIndex == 0) return

      const conversation = this.conversations[conversationIndex]

      this.conversations.splice(conversationIndex, 1)

      this.conversations.unshift(conversation)
    },
    getListIndexByConversationId(conversationId) {
      if (!conversationId) return null

      return this.conversations.findIndex(item => item.id == conversationId)
    },
    getListIndexByConversation(conversation) {
      if (!conversation) return null

      return this.conversations.findIndex(item => item.id == conversation.id)
    },
    selectConversation(conversation) {
      if (!conversation) return this.$emit('input', null)

      this.readConversation(conversation.id)

      this.$store.commit('socket/READ_CHAT', conversation.unreadCount)

      conversation.read()

      this.refreshConversation()

      this.$emit('input', conversation)
    },
    async readConversation(id) {
      asyncPost('/chat/conversations/' + id + '/mark_read', [])
    },
    async getConversations({ textSearch, channels, tags, behaviors }, page = 1) {
      if (!!this.conversationAbortContoller) this.conversationAbortContoller.abort()

      this.conversationAbortContoller = new AbortController()

      this.conversationLoading = true
      this.conversationError = false

      this.currentPage = page

      if (page == 1) this.conversations = []

      try {
        const { data, pagination } = await asyncGet(
          '/chat/conversations',
          {
            page: page,
            search: textSearch,
            channels: channels,
            tags: tags,
            behaviors: !!behaviors ? behaviors.map(item => item.value) : null,
          },
          {
            signal: this.conversationAbortContoller.signal,
          },
        )

        const filtered = data.filter(element => !!element.salechannel)

        this.conversations = [
          ...this.conversations,
          ...filtered
            .map(element => {
              const { _id, unread_count, ref_id, snippet, timestamp, owner, salechannel } = element

              if (salechannel.channel_type == 'line') {
                return new LineConversation(_id, ref_id, timestamp, unread_count, snippet, salechannel, owner)
              }

              return new Conversation(_id, ref_id, timestamp, unread_count, snippet, salechannel, owner)
            })
            .sort((a, b) => b.timestamp - a.timestamp),
        ]

        this.nextPagination = !!pagination.next ? pagination.next : null
        this.conversationAbortContoller = null
      } catch (error) {
        console.log(error)
        if (axios.isCancel(error)) this.conversationError = true
      } finally {
        this.abortController = null
        this.conversationLoading = false
      }
    },
    async handleErrorImage(event) {
      if (event.target) event.target.src = DefaultProfileImage
    },
    textCut(value, length) {
      if (!value) return ''

      if (value.length <= length) return value

      return value.slice(0, length)
    },
    onScroll(event) {
      if (
        !this.conversationLoading &&
        this.nextPagination != null &&
        event.target.scrollHeight - event.target.offsetHeight <= event.target.scrollTop * 1.25
      ) {
        this.getConversations(this.filters, this.nextPagination)
      }
    },
  },
  components: {
    SNSAvartar,
    ChannelLogo,
  },
}
</script>
<style scoped>
.unread-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ee2f2e;
  border-radius: 50%;
}

.chat-list-container {
  position: relative;
  overflow-y: scroll;
}

.chat-list-item {
  height: 80px;
  background-color: white;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(100px);
}

.list-move {
  transition: transform 1s;
}
</style>