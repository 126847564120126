<template>
    <v-avatar 
    :color="!!channelProfile ? '' : '#FE1717'" 
    v-bind="$attrs"
    >
      <img v-if="!!channelProfile" :src="channelProfile">
      <div v-else>{{ channelName }}</div>      
    </v-avatar>    
</template>
<script>
import FBSmall from '@/assets/images/fb_small.png'
export default {
  computed: {
    channelName() {
      if (!this.$attrs.value) return ''

      const name = this.$attrs.value.name

      let ret = ''

      ret += name[0]

      if (name.length > 1) ret += name[1]

      return ret.toLocaleUpperCase()
    },
    channelProfile() {
      if (!this.$attrs.value) return null

      const channel = this.$attrs.value

      if (!!channel.profile) return channel.profile

      return null
    },
    channelTypeAvatar() {
      if (!this.$attrs.value) return null

      const salechannel = this.$attrs.value

      if (salechannel.channel_type == 'sc_fb') return FBSmall
    },
  },
}
</script>