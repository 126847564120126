var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['d-flex', 'flex-grow-1', 'mb-8', { 'flex-row-reverse': !_vm.isOwner }]},[_c('div',{staticClass:"d-flex justify-center px-4"},[_c('v-avatar',{attrs:{"size":"38"}},[(_vm.isOwner)?_c('img',{attrs:{"src":_vm.conversation.ownerProfile},on:{"error":_vm.handleErrorImage}}):_c('channel-logo',{staticClass:"white--text",attrs:{"value":{
          name: _vm.conversation.channelName,
          channel_type: _vm.conversation.type,
          profile: _vm.$attrs.value.isSendByAdmin ? _vm.$attrs.value.adminProfile : _vm.conversation.profile
        },"size":"38"}})],1)],1),_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_vm._l((_vm.$attrs.value.messages),function(message,messageIndex){return [_c('v-tooltip',{key:'message_' + message.mid,attrs:{"left":!_vm.isOwner,"right":_vm.isOwner},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(!!message.replyTo)?_c('reply-to',_vm._g(_vm._b({class:['mb-2', 'base-message',{'mr-auto': _vm.isOwner, 'ml-auto': !_vm.isOwner, 'first': _vm.firstGroup, 'left-message': _vm.isOwner, 'right-message': !_vm.isOwner}],attrs:{"firstGroup":_vm.firstGroup && messageIndex == _vm.$attrs.value.messages.length - 1,"isOwner":_vm.isOwner,"value":message}},'reply-to',attrs,false),on)):(!!message.stickerId && message.type == 'sticker')?_c('line-sticker',{attrs:{"stickerId":message.stickerId}}):_c('message',_vm._g(_vm._b({class:['mb-2', 'base-message',{'mr-auto': _vm.isOwner, 'ml-auto': !_vm.isOwner, 'white--text': !_vm.isOwner, 'first': _vm.firstGroup, 'left-message': _vm.isOwner, 'right-message': !_vm.isOwner}],attrs:{"firstGroup":_vm.firstGroup && messageIndex == _vm.$attrs.value.messages.length - 1,"isOwner":_vm.isOwner,"value":message}},'message',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(message.time))])]),(message.isTmp)?_c('div',{staticClass:"text-right"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"16"}}),_vm._v(" กำลังส่ง. . .")],1):_c('div',{class:['d-flex', 'flex-row','text-xs', 'text--disabled', {'justify-end': !_vm.isOwner}]},[(_vm.$attrs.value.isSendByAdmin && messageIndex == _vm.$attrs.value.messages.length - 1)?_c('div',[_vm._v("ส่งโดย "+_vm._s(_vm.$attrs.value.adminName))]):_vm._e(),(messageIndex == _vm.$attrs.value.messages.length - 1 || _vm.$attrs.value.messages[messageIndex + 1].isTmp)?[(!_vm.isOwner)?_c('div',{staticClass:"read-container success--text"},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(_vm._s(_vm.icons.mdiCheck))])],1):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.$attrs.value.messages[_vm.$attrs.value.messages.length -1].time))])]:_vm._e()],2)]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }