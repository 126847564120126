<template>    
    <div class="d-flex h-full flex-column" style="background-color: #fff;height: 100%;overflow:hidden; position: relative;">                              
        <v-form class="h-full d-flex flex-column" style="height: calc(100vh - 40px)">
            <div class="text-h6 px-6 py-3 d-flex align-center">
                สร้างรายการขาย <v-btn icon class="ml-auto" @click="$emit('closeOrderCreator')"><v-icon>{{ icons.mdiClose }}</v-icon></v-btn>
            </div>
            <v-divider></v-divider>
            <div class="pa-4">
                <delay-autocomplete
                outlined
                hide-selected
                cache-items
                v-model="$attrs.value.warehouse"
                label="ตัดสต็อคที่ คลัง/สาขา"
                placeholder="พิมพ์เพื่อค้นหาคลัง (เว้นว่างหากไม่เลือก)"
                no-data-text="ไม่พบข้อมูล"
                :url="'/saleorder/warehouseList'"
                dense
                return-object
                ref="salechannelAutocomplete"                
                item-text="name"
                item-value="id"
                hide-details
                :disabled="!$attrs.value.isWarehouseChangeable || mode == 'read'"
                ></delay-autocomplete>

                <v-textarea
                outlined
                v-model="$attrs.value.note"
                label="หมายเหตุร้านค้า"
                placeholder="หมายเหตุของร้านค้า *ลูกค้าไม่เห็นตรงนี้"
                rows="1"
                dense
                hide-details
                class="mt-2"
                :disabled="mode == 'read'"
                ></v-textarea>
            </div>

            <div class="ma-auto text-center" v-if="$attrs.value.orderItems.length == 0">
              <div>
                <v-icon size="200">{{ icons.mdiArchiveOutline }}</v-icon>
              </div>          
              <div>
                <v-btn 
                large 
                block 
                class="success" 
                @click="addProductDialog = true"
                :disabled="!$attrs.value.isItemEditable || mode == 'read'"
                ><v-icon>{{ icons.mdiPlus }}</v-icon>เพิ่มสินค้า</v-btn>
              </div>          
            </div>            
            <v-container v-else class="py-0 h-full" style="overflow-y: scroll;" ref="container">
              <template
              v-for="(item, index) in $attrs.value.orderItems"
              class="d-flex flex-column pa-2"
              >
                <v-row :key="'1_' + index">
                  <v-col class="d-flex">
                    <light-box v-if="!!item.imageURL"
                    :src="item.imageURL"
                    max-width="70"
                    max-height="70"
                    ></light-box>
                    <product-image v-else :image="null" />

                    <div class="d-flex flex-column ml-2">
                        <div class="black--text">{{ item.name }}</div>
                        <div class="mt-auto text-caption black--text">{{ item.sku }}</div>
                    </div>
                    <div>
                      <v-btn 
                      icon 
                      @click="deleteItem(index)"
                      :disabled="!$attrs.value.isItemEditable || mode == 'read'"
                      >
                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                    </div>
                  </v-col>                    
                </v-row>  
                <v-row :key="'2_' + index" dense class="mt-2">
                  <v-col cols="6" sm="3">
                    <v-text-field
                        class="input-align-right"
                        type="number"
                        suffix="฿"
                        outlined
                        dense
                        v-model.number="item.price"
                        :rules="[validators.minValueValidator(item.price, 0)]"
                        label="ราคาต่อหน่วย"
                        hide-details
                        :disabled="!$attrs.value.isItemEditable || mode == 'read'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <toogle-text-field
                      class="input-align-right my-auto"
                      type="number"
                      outlined
                      dense
                      v-model.number="item.discount"
                      v-bind:toogleValue.sync="item.discount_type"
                      :rules="getDiscountRules(item.discount, item.discount_type)"
                      label="ส่วนลด/หน่วย"
                      hide-details
                      :disabled="!$attrs.value.isItemEditable || mode == 'read'"
                    ></toogle-text-field>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      class="input-align-right my-auto"
                      type="number"                            
                      outlined
                      dense
                      v-model.number="item.quantity"
                      :rules="[validators.minValueValidator(item.quantity, 1), validators.integerValidator]"   
                      label="จำนวน"
                      hide-details
                      :disabled="!$attrs.value.isItemEditable || mode == 'read'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3" class="text-right pt-3">
                    {{ item.priceSum }} ฿                      
                  </v-col>
                </v-row>                
              </template>
              <v-row no-gutters ref="latest"></v-row>
            </v-container>                                        
                        
            <div v-if="$attrs.value.orderItems.length > 0" class="mt-auto">
              <v-btn               
              block 
              class="success" 
              @click="addProductDialog = true"
              :disabled="!$attrs.value.isItemEditable || mode == 'read'"
              ><v-icon>{{ icons.mdiPlus }}</v-icon>เพิ่มสินค้า</v-btn>   
            </div>            
            <v-divider></v-divider>            
            <v-simple-table>
              <tbody>
                <tr class="black--text">
                  <td class="text-body-1" width="50%" style="border-bottom: none">ราคาก่อนส่วนลด</td>
                  <td class="text-body-1 text-right" style="border-bottom: none">{{ $attrs.value.priceBeforeDiscount | formatNumber }}</td>
                </tr>
                <tr>
                  <td width="50%" style="border-bottom: none" class="text-body-1">ส่วนลดทั้งบิล</td>
                  <td style="border-bottom: none">
                    <toogle-text-field
                      class="input-align-right"
                      type="number"
                      outlined
                      dense
                      v-model.number="$attrs.value.discount"
                      v-bind:toogleValue.sync="$attrs.value.discount_type"
                      :rules="getDiscountRules($attrs.value.discount, $attrs.value.discount_type)"                          
                      hide-details
                      :disabled="!$attrs.value.isItemEditable || mode == 'read'"
                    ></toogle-text-field>
                  </td>
                </tr>
                <tr>
                  <td width="50%" style="border-bottom: none" class="text-body-1">วิธีจัดส่ง</td>
                  <td style="border-bottom: none">
                    <delay-autocomplete
                      outlined
                      hide-selected
                      cache-items
                      v-model="$attrs.value.delivery_method"                                       
                      placeholder="พิมพ์เพื่อค้นหา"
                      no-data-text="ไม่พบข้อมูล"
                      :url="'/saleorder/delivery'"
                      dense
                      return-object
                      ref="deliveryAutocomplete"
                      :autoSelectFirst="true"     
                      item-text="name"
                      item-value="id"             
                      @change="$attrs.value.delivery_cost = $event.default_cost"
                      hide-details
                      :disabled="!$attrs.value.isDeliveryInfoEditable || mode == 'read'"
                    ></delay-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td width="50%" style="border-bottom: none" class="text-body-1">ค่าจัดส่ง</td>
                  <td style="border-bottom: none">
                    <v-text-field
                        class="input-align-right"
                        type="number"                            
                        outlined
                        dense
                        v-model.number="$attrs.value.delivery_cost"
                        suffix="฿"
                        :rules="[validators.minValueValidator($attrs.value.delivery_cost, 0)]"
                        hide-details
                        :disabled="!$attrs.value.isDeliveryInfoEditable || mode == 'read'"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td width="50%" style="border-bottom: none" class="text-body-1">รวมทั้งหมด</td>
                  <td class="text-right text-body-1" style="border-bottom: none">{{ $attrs.value.totalPrice | formatNumber }}</td>
                </tr>
                <tr>
                  <td width="50%" style="border-bottom: none" class="text-body-1">ตั้งค่าอื่นๆ</td>
                  <td>
                    <v-checkbox
                    label="เป็นบิล COD"
                    v-model="$attrs.value.is_cod"     
                    :disabled="!$attrs.value.isCODEditable || mode == 'read'"              
                    ></v-checkbox>
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <v-btn 
                    block 
                    outlined 
                    color="primary" 
                    :loading="sendingData"
                    v-if="$attrs.value.isSaveableAsDraft"
                    @click="submitOrder(true)"
                    >บันทึกแบบร่าง</v-btn>
                    <v-btn 
                    block 
                    outlined 
                    color="black" 
                    :loading="sendingData"
                    @click="cancelOrder($attrs.value.id, $attrs.value.orderNumber)"
                    :disabled="!$attrs.value.isCancellable"
                    v-else
                    >ยกเลิก</v-btn>
                  </td>                    
                  <td width="50%">
                    <v-btn 
                    block 
                    color="primary" 
                    @click="submitOrder"
                    :loading="sendingData"
                    >{{ $attrs.value.id && $attrs.value.status != 'draft' ? 'แก้ไขบิล' : 'ส่งบิลให้ลูกค้า' }}</v-btn></td>
                </tr>
              </tbody>
            </v-simple-table>            
        </v-form>

      <v-dialog
        v-model="addProductDialog"
        transition="dialog-bottom-transition"
        max-width="400"
      >
        <v-card>
          <v-toolbar
            flat            
            color="primary"            
          >            
            <v-toolbar-title class="white--text">เพิ่มสินค้า</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="addProductDialog = false"
            >
              <v-icon color="white">{{ icons.mdiClose }}</v-icon>
            </v-btn>            
          </v-toolbar>

          <v-card-text class="mt-4">
            <popup-product-input
            :url="'saleorder/searchProduct?warehouse_id=' + $attrs.value.warehouseId"
            @addProduct="addItemFromProductInput"
            ></popup-product-input>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>    
</template>
<script>
import { OrderItem } from '@/classes/order/OrderItem'
import ToogleTextField from '@/components/ToogleTextField.vue'
import { asyncDelete, asyncPostAsJson } from '@/helpers/asyncAxios'
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
} from '@core/utils/validation'
import { mdiArchiveOutline, mdiClose, mdiDeleteOutline, mdiPlus } from '@mdi/js'
import PopupProductInput from './PopupProductInput.vue'
export default {
  data() {
    return {
      searchItem: null,
      addProductDialog: null,
      sendingData: false,
      mode: 'create',
      icons: {
        mdiClose,
        mdiArchiveOutline,
        mdiPlus,
        mdiDeleteOutline,
      },
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
      },
    }
  },
  created() {
    this.mode = this.$attrs.value.id && this.$attrs.value.status ? 'edit' : 'create'
  },
  methods: {
    filterItem(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.sku && item.sku.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1) ||
        (item.barCode && item.barCode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    },
    addItemFromProductInput(product) {
      const order = this.$attrs.value

      const { id, name, images, sku, barCode, defaultPrice, discountedPrice, vat, available } = product

      order.addOrderItem(
        new OrderItem(
          id,
          images && images.length > 0 ? images[0].URI : null,
          name,
          sku,
          barCode,
          null,
          1,
          defaultPrice,
          !!discountedPrice ? defaultPrice - discountedPrice : 0,
          !!discountedPrice ? 'value' : 'percent',
          7,
        ),
      )

      let text = name

      if (sku) {
        text += '(' + sku + ')'
      }

      this.$notify({
        group: 'addProduct',
        title: 'เพิ่มสินค้าสำเร็จ',
        text: text,
        type: 'success',
      })

      this.$nextTick().then(() => {
        const element = this.$refs.latest

        if (!element) return

        element.scrollIntoView({ behavior: 'smooth' })
      })
    },
    deleteItem(index) {
      const order = this.$attrs.value
      const newOrderItems = [...order.orderItems]
      newOrderItems.splice(index, 1)
      order.orderItems = newOrderItems

      this.$emit('input', order)
    },
    getDiscountRules(discount_value, discount_type) {
      return discount_type == 'percent'
        ? [minValueValidator(discount_value, 0), maxValueValidator(discount_value, 100)]
        : [minValueValidator(discount_value, 0)]
    },
    async submitOrder(is_draft = false) {
      this.sendingData = true
      try {
        const sendData = {
          ...this.$attrs.value.serialize(),
          is_draft: is_draft,
        }

        if (!this.$attrs.value.id) {
          await asyncPostAsJson('/saleorder', sendData)
        } else {
          sendData['_method'] = 'PUT'
          await asyncPostAsJson('/saleorder/' + this.$attrs.value.id, sendData)
        }
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false

      this.$emit('closeOrderCreator')
      this.$emit('refreshMetadata')
    },
    async cancelOrder(id, orderNumber) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยกเลิก ?',
        body:
          'คุณต้องการยกเลิกบิลหมายเลข "' +
          orderNumber +
          '" ใช่หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถแก้ไขสถานะกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      this.sendingData = true
      try {
        await asyncDelete('/saleorder/delete', {
          items: [id],
        })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.sendingData = false

      this.$emit('closeOrderCreator')
      this.$emit('refreshMetadata')
    },
  },
  components: {
    PopupProductInput,
    ToogleTextField,
  },
}
</script>
<style lang="scss">
</style>