<template>
    <div>
        <div class="d-flex flex-column justify-center align-center">
            <v-avatar
            size="150"
            class="mt-6"
            >
                <img                 
                :src="conversation.ownerProfile" 
                @error="handleErrorImage">          
            </v-avatar>
            <div class="text-h5 mt-2 black--text">
                {{ conversation.ownerName }}
            </div>
            <div class="mt-2">
                เป็นลูกค้าครั้งแรกเมื่อ : {{ !!customer ? helpers.convertTimestampToAooDefault(customer.created_at) : 'ยังไม่เคยซื้อของ' }}
            </div>
        </div>
        <div v-if="customer" class="px-4">
            <customer-tag
                :value="customer.tags"
                :isAddable="false"
                @removeTag="$emit('removeTag', $event)"
            ></customer-tag>
        </div>
        <!-- TODO : total buy -->
    </div>    
</template>
<script>
import DefaultProfileImage from '@/assets/images/avatars/1.png'
import { convertTimestampToAooDefault } from '@/helpers/converter'
import CustomerTag from '../CustomerTag.vue'
export default {
  props: ['conversation', 'customer'],
  data() {
    return {
      helpers: {
        convertTimestampToAooDefault,
      },
    }
  },
  methods: {
    async handleErrorImage(event) {
      if (event.target) event.target.src = DefaultProfileImage
    },
  },
  components: {
    CustomerTag,
  },
}
</script>